import { userProgressController } from '../UserProgressControl'
import { genHelpObjs } from './GeneralHelpHints'

export const isToolMatched = (tool, match) =>
  !!(tool && match &&
    (tool.matches.includes(match.toLowerCase()) ||
      (match[match.length - 1] === 's' && tool.matches.includes(match.toLowerCase().slice(0, -1)))))

export const getUnlockedTools = () => genHelpObjs
  .filter(obj => userProgressController.userProgress.toolsFound.includes(obj.name))
  .sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : (a.name.toUpperCase() === b.name.toUpperCase() ? 0 : +1))

export const getAllTools = () => genHelpObjs
  .sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : (a.name.toUpperCase() === b.name.toUpperCase() ? 0 : +1))

export const getFilteredTools = filter => genHelpObjs
  .filter(filter)
  .sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : (a.name.toUpperCase() === b.name.toUpperCase() ? 0 : +1))

export const getTool = tool =>
  genHelpObjs.find(o => isToolMatched(o, tool))

export const isTool = tool =>
  genHelpObjs.some(o => isToolMatched(o, tool))

export const isOwnedTool = tool =>
  getUnlockedTools().some(o => isToolMatched(o, tool))

export const isToolValid = (tool) => {
  for (const toolObj of getAllTools()) {
    if (isToolMatched(toolObj, tool)) {
      return true
    }
  }
}