import { getActiveGroup } from '../../groups/group-use-cases/getActiveGroup'
import { getActiveMission } from './getActiveMission'
import { readFlowProgressFromUserProgressStore } from '../../user-progress/user-progress-store'
import { setContentSequence } from '../content-director-store'
import { ContentSequenceItemTypes } from '../ContentSequenceItemTypes'
import { getAssignmentsFromGroupId } from '../../groups/group-use-cases/getAssignmentsFromGroupId'
import { getActiveMissionPack } from './getActiveMissionPack'


function makeFlowContentSequenceItem(flowItem) {
  const { id, obj, flowIndex, type, typeIndex, scored } = flowItem
  const flowProgress = readFlowProgressFromUserProgressStore(id) ?? {}
  return {
    id,
    flowIndex,
    flowType: type,
    flowTypeIndex: typeIndex,
    title: obj.title,
    completed: !!flowProgress.completed,
    scored,
  }
}

function formatAssignmentContentSequenceItems(assignments, contentSequence) {
  assignments.forEach((assignment) => {
    if (assignment.type === 'flow') {
      const seqIdx = contentSequence.findIndex(item => item?.flow?.id === assignment.flowId)

      if (seqIdx === -1) {
        return
      }
      contentSequence[seqIdx].assignment = assignment
    }
  })
}

// eslint-disable-next-line no-unused-vars
function formatProgressionGateContentSequenceItems(progressionGates, contentSequence) {
  progressionGates.forEach((progressionGate) => {
    const seqIdx = contentSequence.findIndex(item => item?.flow?.id === progressionGate.flowId)
    if (seqIdx === -1) {
      return
    }
    if (progressionGate.source === 'assignment') {
      contentSequence[seqIdx].progressionGate = progressionGate
      return
    }

    if (seqIdx === 0) {
      contentSequence.unshift({ contentSequenceType: ContentSequenceItemTypes.PROGRESSION_GATE, progressionGate })
      return
    }

    contentSequence.splice(seqIdx, 0, { contentSequenceType: ContentSequenceItemTypes.PROGRESSION_GATE, progressionGate })
  })
}

// function isContentSequenceItemLocked(contentSequenceItem, lastContentSequenceItem) {
//   if (contentSequenceItem.contentSequenceType !== 'flow') {
//     return true
//   }

//   const previousItemCompleted = lastContentSequenceItem === null || lastContentSequenceItem.completed
//   return contentSequenceItem.activeMission
// }

function getLastCompletedFlowIndex(contentSequence) {
  let lastFlowIdx = -1
  for (const i in contentSequence) {
    const contentSequenceItem = contentSequence[i]
    if (contentSequenceItem.contentSequenceType === ContentSequenceItemTypes.FLOW && contentSequenceItem.flow.completed) {
      lastFlowIdx = parseInt(i)
    }
  }

  return lastFlowIdx
}

function formatContentSequenceItemsLockStatus(contentSequence, activeMission) {
  const lastCompletedFlowItem = getLastCompletedFlowIndex(contentSequence)
  let progressionGateLocked = false

  contentSequence.forEach((contentSequenceItem, idx) => {
    if (contentSequenceItem.contentSequenceType === ContentSequenceItemTypes.PROGRESSION_GATE) {
      progressionGateLocked = contentSequenceItem.progressionGate.locked
      contentSequenceItem.locked = false
      return
    }

    contentSequenceItem.locked = (!(contentSequenceItem.flow.completed || (lastCompletedFlowItem + 1 === idx)) ||
    progressionGateLocked) &&
    !isFlowItemUnlocked(contentSequenceItem.flow.id, activeMission)
    contentSequenceItem.gated = progressionGateLocked
  })
}

function isFlowItemUnlocked(flowItemId, activeMissionPack) {
  if (activeMissionPack?.unlocked) {
    return true
  }

  const activeGroup = getActiveGroup()
  if (activeGroup?.unlockedLessonItemIds) {
    return activeGroup.unlockedLessonItemIds.includes(flowItemId)
  }

  return false
}

function mergeActiveGroupAndLessonContentSequence() {
  const activeMission = getActiveMission()
  if (!activeMission) {
    return
  }

  const contentSequence = activeMission.flow.map((flowItem) => {
    return {
      contentSequenceType: ContentSequenceItemTypes.FLOW,
      flow: makeFlowContentSequenceItem(flowItem),
    }
  })


  const activeGroup = getActiveGroup()
  if (!activeGroup){
    formatContentSequenceItemsLockStatus(contentSequence)
    setContentSequence(contentSequence)
    return
  }

  // const { assignments, progressionGates } = activeGroup
  const assignments = getAssignmentsFromGroupId(activeGroup.id)
  // These functions mutate the content sequence array.
  if (assignments) {
    formatAssignmentContentSequenceItems(assignments, contentSequence)
  }
  if (activeGroup.progressionGates) {
    formatProgressionGateContentSequenceItems(activeGroup.progressionGates, contentSequence)
  }
  const activeMissionPack = getActiveMissionPack()
  formatContentSequenceItemsLockStatus(contentSequence, activeMissionPack)

  setContentSequence(contentSequence)
}

export { mergeActiveGroupAndLessonContentSequence }