import React from 'react'
import { useSnackbar } from 'notistack'
import { TargetStates, TargetModes } from '../TargetInterface'
import { CodePanelActions, useCodePanel } from './CodePanelContext'
import { useUserConfig } from './UserConfigContext'
import { enableSound, set3dSound, setVolume } from '../AudioControls'
import { validatorController, ValidatorContextKeys } from '../ValidatorControl'
import { editorController } from '../EditorControl'
import { usePreviousState } from '../utils/usePreviousState'
import { useLogin } from './LoginContext'
import { useMission, MissionActions } from './MissionContext'
import { useScene } from './SceneContext'
import { userSessionStore } from '../content-manager/user-session/user-session-store'
import { Targets } from '../Players'

export const DebuggerActions = Object.freeze({
  RUN: Symbol('run state'),
  STOP_RUN: Symbol('stop run state'),
  DEBUG: Symbol('debug state'),
  DEBUG_USER_COMMAND: Symbol('debug user command'),
  DEBUG_SHOW_TARGET_SELECT: Symbol('show target select dialog'),
})

validatorController.addContextActions(ValidatorContextKeys.DEBUGGER, DebuggerActions)

const DebuggerContext = React.createContext()

export const DebuggerProvider = ({ children }) => {
  const snacks = useSnackbar()
  const [codePanelState, codePanelDispatch] = useCodePanel()
  const [userConfigState] = useUserConfig()
  const [loginState] = useLogin()
  const [, missionDispatch] = useMission()
  const [sceneState, sceneDispatch] = useScene()
  const prevLoginState = usePreviousState(loginState)
  const [state, setState] = React.useState({
    targetState: TargetStates.STOPPED,
    mode: TargetModes.DISCONNECTED,
    debugInfo: { line: -1, variables: {} },
    stdoutText: '(Console output here)<br />',
    showTargetSelectDialog: false,
  })

  // Grab the latest 'codePanelDispatch' and place it in a ref.
  // This is only to provide the debug target with an updated reference
  // to the codePanelDispatch function (containing latest state variables within its scope)
  // without constantly removing/re-adding a callback that contains a new version.
  // The ref takes care of that.
  const codePanelDispatchRef = React.useRef()
  React.useEffect(() => {
    codePanelDispatchRef.current = codePanelDispatch
  }, [codePanelDispatch])

  const missionDispatchRef = React.useRef()
  React.useEffect(() => {
    missionDispatchRef.current = missionDispatch
  }, [missionDispatch])

  const sceneDispatchRef = React.useRef()
  React.useEffect(() => {
    sceneDispatchRef.current = sceneDispatch
  }, [sceneDispatch])

  /**
   * Halt any ongoing run/debug if anything about the loginState changes
   * (namely, upon login/logout)
   */
  React.useEffect(() => {
    if (prevLoginState === loginState) {
      return
    }
    if (state.targetState === TargetStates.RUNNING ||
      state.targetState === TargetStates.LOADING ||
      state.targetState === TargetStates.AWAITING_INPUT) {
      if (state.mode === TargetModes.RUN) {
        sceneState.backend.stopRun()
      } else if (state.mode === TargetModes.DEBUG) {
        sceneState.backend.stopDebug()
      }
    }
  }, [loginState, prevLoginState, state.mode, sceneState.backend, state.targetState])

  // React.useEffect(() => {
  //   // Start REPL on first load. Must do this after target is loaded.
  //   if (!sceneState.sceneFirstLoad && sceneState.backend && sceneState.target) {
  //     sceneState.backend.repl()
  //   }
  //   // only trigger on a change to sceneFirstLoad
  // }, [sceneState.sceneFirstLoad]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // Stop user code and restart repl on scene load. No effect if repl already running.
    if (sceneState.sceneIsResetting && !sceneState.sceneFirstLoad && sceneState.backend) {
      sceneState.backend.stopRun()
    }
  }, [sceneState.sceneFirstLoad, sceneState.sceneIsResetting, sceneState.backend])

  React.useEffect(() => {
    const handleStateChange = (targetState) => {
      const actionSuffix = sceneState.target.startsWith('USB') ? '_usb' : ''
      if (targetState === TargetStates.STOPPED) {
        missionDispatchRef.current({ type: MissionActions.HANDLE_DEBUGGER_RUN, runAction: 'stop' + actionSuffix })
        snacks.enqueueSnackbar('Program Ended', {
          variant: 'info',
        })
      } else if (targetState === TargetStates.LOADING) {
        missionDispatchRef.current({ type: MissionActions.HANDLE_DEBUGGER_RUN, runAction: 'start' + actionSuffix })
      }
      if (targetState !== TargetStates.AWAITING_INPUT && targetState !== TargetStates.STOPPED) {
        editorController.preventAddBreakpoint = true
      } else {
        editorController.preventAddBreakpoint = false
      }
      setState((state) => {
        return { ...state, targetState }
      })
    }
    const handleModeChange = (mode) => {
      setState((state) => {
        return { ...state, mode }
      })
    }
    const handleDebugChange = async (debugInfo) => {
      // console.log(debugInfo.variables)
      // TODO Is there a better place to do this?

      if (debugInfo.line === undefined) {
        debugInfo.line = state.debugInfo.line
        debugInfo.name = state.debugInfo.name
        debugInfo.file = undefined
      }
      if (debugInfo.variables === undefined) {
        debugInfo.variables = state.debugInfo.variables
      }
      try {
        if (!!debugInfo.file) {
          // Note we're calling the reference's 'current' value here
          await codePanelDispatchRef.current({ type: CodePanelActions.OPEN_TAB_BY_FILENAME, filename: debugInfo.file })
        }
        editorController.handleLineNumberChange(debugInfo.line)
      } catch (err) {
        // If the tab could not be opened, we should halt the program
        sceneState.backend.stopRun()
      }
      setState((state) => {
        return { ...state, debugInfo }
      })
    }
    sceneState.backend.addCallbacks(handleStateChange, handleModeChange, handleDebugChange)
    return () => {
      sceneState.backend.removeCallbacks(handleStateChange, handleModeChange, handleDebugChange)
    }
  }, [snacks, state.mode, sceneState.backend, state.debugInfo, sceneState.target])

  const initSound = () => {
    // This needs to be invoked by a user interaction 'gesture'
    enableSound()
    setVolume(userConfigState.soundVolume)
    set3dSound(userConfigState.spatialSound)
  }

  const checkRun = () => {
    const action = snackbarId => (
      <>
        <button style={{marginRight:'1em'}} onClick={() => {
          setState(state => ({...state, showTargetSelectDialog: true}))
          snacks.closeSnackbar(snackbarId)
        }}>
          Select Target
        </button>
        <button onClick={() => {
          snacks.closeSnackbar(snackbarId)
        }}>
          Cancel
        </button>
      </>
    )

    const target = sceneState.backend?.deviceConnected
    if (target === Targets.UNCONNECTED || target === Targets.SIM_CODEX) {
      const messages = {}
      messages[Targets.UNCONNECTED] = 'No Device Target for Code!'
      messages[Targets.SIM_CODEX] = 'Sim CodeX cannot run code (yet!)'
      snacks.enqueueSnackbar(messages[target], {
        action,
        variant: 'error',
        persist: true,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      })
      return false
    }

    return true
  }

  const dispatch = async (action) => {
    validatorController.handleContextEvent(action)

    switch (action.type) {
      case DebuggerActions.RUN: {
        if (!checkRun()) {
          break
        }
        if (
          state.mode === TargetModes.RUN &&
          (state.targetState === TargetStates.RUNNING ||
            state.targetState === TargetStates.LOADING)
        ) {
          sceneState.backend.stopRun()
        } else {
          userSessionStore.clearUserSessionLastErrorMessage()
          // analytics.trackEvent('Run')
          initSound()
          const code = codePanelState.editorInstance.getValue()
          const selectedLanguage = userConfigState.codeLanguage
          validatorController.handleRunCode(code)
          try {
            await sceneState.backend.run(code, selectedLanguage)
          } catch (error) {
            console.error(error)
          }
        }
        break
      }
      // { type: ... }
      case DebuggerActions.DEBUG: {
        if (!checkRun()) {
          break
        }
        initSound()
        // The code and the filename were stashed in the editorInstance at the same time
        // TODO I think both of these pieces of data should live somewhere else, but at
        // least they are being kept TOGETHER...
        const code = codePanelState.editorInstance.getValue()
        const filename = codePanelState.filename
        const selectedLanguage = userConfigState.codeLanguage
        validatorController.handleRunCode(code)
        try {
          await sceneState.backend.debug(filename, code, selectedLanguage)
        } catch (error) {
          console.error(error)
        }
        break
      }
      // { type: ..., debugCommand: Any }
      case DebuggerActions.DEBUG_USER_COMMAND: {
        sceneState.backend.debugCommands(action.debugCommand)
        if (!editorController.removeHighlightWithBreakpoint()) {
          editorController.deleteHighlightedLine()
        }
        break
      }
      // { type: ..., shouldShow: Bool }
      case DebuggerActions.DEBUG_SHOW_TARGET_SELECT: {
        setState(state => ({...state, showTargetSelectDialog: action.shouldShow}))
        break
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type.toString()}`)
      }
    }
  }
  return (
    <DebuggerContext.Provider value={{ state, dispatch }}>
      {children}
    </DebuggerContext.Provider>
  )
}

export const useDebugger = () => {
  const { state, dispatch } = React.useContext(DebuggerContext)

  if (state === undefined || dispatch === undefined) {
    throw new Error(
      'useDebugger must be used within a child of a DebuggerProvider'
    )
  }
  return [state, dispatch]
}
