import React from 'react'
import { simScene } from '../SimScene'
import { environmentList } from '../entities/Environments'
import { cameraList } from '../entities/Cameras'
import { appDefaults } from '../BotSimDefaults'
import { validatorController, ValidatorContextKeys } from '../ValidatorControl'
import { merge } from 'lodash'
import { useSnackbar } from 'notistack'
import {
  codeRunner,
  simDebugBackend,
  usbDebugBackend,
  Targets,
  webTerminal,
  codeBotCB2ModelOne,
  codeXModelOne,
  codeBotCtrl,
  codeXCtrl,
} from '../Players'
import { editorController } from '../EditorControl'
import { resetScore } from '../content-manager/content-director/content-director-use-cases/score-actions'

export const SceneActions = Object.freeze({
  ENVIRONMENT_SET: Symbol('set environment'),
  ENVIRONMENT_RESET: Symbol('reset environment'),
  CAMERA_SET: Symbol('set camera'),
  CAMERA_RESET: Symbol('reset camera'),
  CAMERA_HELP: Symbol('camera help'),
  TARGET_DEVICE_SET: Symbol('target state'),
  TARGET_AND_ENVIRONMENT_SET: Symbol('environment and target for objective change'),
  VOLUME_SET: Symbol('set volume'),
  VOLUME_SHOW: Symbol('show volume controls'),
  PLAY_MISSION_COMPLETION_MUSIC: Symbol('play mission completion music'),
})

validatorController.addContextActions(ValidatorContextKeys.SCENE, SceneActions)

function isDeviceTarget(device, target) {
  if (device === target) {
    return true
  }

  if (target === Targets.USB_CODEBOT) {
    return device === Targets.USB_CB2 || device === Targets.USB_CB3
  }
}

function simulatedTargetParamsOverride(target, oldParams) {
  var params = oldParams

  if (!!params && Object.keys(params).length !== 0) {
    return params
  }

  switch (target) {
    case Targets.SIM_CODEX:
      params = {
        'bot': [
          {
            'playerPosition': [
              0,
              1,
              0,
            ],
          },
        ],
      }
      break
    default:
      break
  }
  return params
}

const SceneContext = React.createContext()
export const SceneProvider = ({ children }) => {
  // Creation and Connection
  const [showCameraHelp, setShowCameraHelp] = React.useState(false)
  const envIndex = React.useRef(appDefaults.sceneConfig.envIndex)
  const [envParams, setEnvParams] = React.useState(null)
  const [camIndex, setCamIndex] = React.useState(appDefaults.sceneConfig.camIndex)
  // const [player, setPlayer] = React.useState(codeBotCB2ModelOne)
  const [backend, setBackend] = React.useState(simDebugBackend)
  const [target, setTarget] = React.useState(Targets.UNCONNECTED)  // 'target' is selected device
  const [device, setDevice] = React.useState(Targets.UNCONNECTED)  // 'device' is what the backend reports is connected
  const player = React.useRef(null)
  const camera = React.useRef()
  // Is this not the first time we're "rendering" this component?
  const [sceneIsResetting, setSceneIsResetting] = React.useState(false)
  const sceneFirstLoad = React.useRef(true)
  const snacks = useSnackbar()
  const pendingEnvironmentChanges = React.useRef([])
  const activeEnvIndex = React.useRef(null)
  const [volumeParams, setVolumeParams] = React.useState([40, 70])  // Music, SFX volume slider 0-100 values
  const [volumeShow, setVolumeShow] = React.useState(false)  // show popup controls


  React.useEffect(() => {
    player.current = null
    simDebugBackend.deviceConnected = Targets.UNCONNECTED
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  const deviceConnected = () => {
    setDevice(backend.deviceConnected)
  }
  usbDebugBackend.setDeviceConnCb(deviceConnected)
  simDebugBackend.setDeviceConnCb(deviceConnected)

  const audioTaperVolumeParams = (volumeParams) => {
    // Convert volume slider 0-100 values to Audio 0.0-1.0 values using an exponential taper.
    return volumeParams.map(p => (p / 100)**2.5)
  }

  const handleEnvironmentChange = async (envParams) => {
    /* Handle requested change to new environment specified as 'envIndex.current'.
        Scene loading can take time, and requests to change environment can be made while a change is in progress,
        so this function queues pending change requests.
    */

    // Don't allow multiple intermediate changes. Our max queue size is 2: [in_progress, next]
    // Note: currently you can trigger this case via a bug when you toggleTestMode 3 or more times rapidly.
    if (pendingEnvironmentChanges.current.length === 2) {
      // console.log('Discarded intermediate environment change.')
      pendingEnvironmentChanges.current.pop()
    }

    pendingEnvironmentChanges.current.push([envParams, envIndex.current])
    if (pendingEnvironmentChanges.current.length === 1) {
      // Queue was empty, initiate processing
      setSceneIsResetting(true)
      while (pendingEnvironmentChanges.current.length > 0) {
        await doEnvironmentChange(...pendingEnvironmentChanges.current[0])
        pendingEnvironmentChanges.current.shift()
      }
      setSceneIsResetting(false)
    } else {
      // console.log(`--> Queueing handleEnvironmentChange to: ${environmentList[envIndex.current].constructor.name} (${pendingEnvironmentChanges.current.length} in queue)`)
    }
  }

  const doEnvironmentChange = async (envParams, newEnvIndex) => {
    // console.trace(`Change environment from ${environmentList[activeEnvIndex.current]?.constructor.name} to ${environmentList[newEnvIndex].constructor.name}`, envParams)
    try {
      // Detach target from camera before removing target entity (critical for AttachedCam, possibly others)
      camera.current.setTargetEntity(null)
      simScene.setActiveTargetEntity(null, null)

      if (player.current !== null) {
        await simScene.removeEntity(player.current)
      }
      if (!sceneFirstLoad.current) {
        await simScene.removeEntity(environmentList[activeEnvIndex.current])
        await simScene.sceneReadyPromise()
      }

      simScene.setSceneDefaults()
      const env = environmentList[newEnvIndex]
      env.setOptions(envParams)
      env.setVolume(audioTaperVolumeParams(volumeParams))
      await simScene.addEntity(env)
      simScene.setActiveEnvironmentEntity(env)

      if (player.current !== null) {
        // Environments can specify player attributes
        if (env.getPlayerAttributes) {
          // Just one player for now :-)
          const p0 = env.getPlayerAttributes(0)
          player.current.initialPosition = p0.initialPosition
          player.current.initialRotation = p0.initialRotation
          player.current.peripheralLoad = p0.peripherals
        }
        await simScene.addEntity(player.current)

        // Inform camera of target model, but allow camera position to be retained if just resetting same environment.
        const isSceneChange = activeEnvIndex.current !== newEnvIndex
        camera.current.setTargetEntity(player.current, isSceneChange, envParams?.camera)

        simScene.setActiveTargetEntity(player.current, backend.codeRunner?.modelController)
      }

      await simScene.sceneReadyPromise()

      sceneFirstLoad.current = false
    } catch (err) {
      console.log('Error in handleEnvironmentChange: ', err)
      snacks.enqueueSnackbar('Unable to load environment - Check Network', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }

    // Keep track of active environment loaded into scene
    activeEnvIndex.current = newEnvIndex
  }

  const setScenePlayer = async (target) => {
    let model = null
    let modelCtrl = null
    if (target === Targets.SIM_CB2) {
      model = codeBotCB2ModelOne
      modelCtrl = codeBotCtrl
    } else if (target === Targets.SIM_CODEX) {
      model = codeXModelOne
      modelCtrl = codeXCtrl
    } else {
      return
    }
    codeRunner.setModelController(modelCtrl)
    validatorController.setModelController(modelCtrl)
    await simScene.addEntity(model)
    simScene.setActiveTargetEntity(model, modelCtrl)
    camera.current.setTargetEntity(model, false)
    player.current = model
  }

  // onCameraChange
  React.useEffect(() => {
    const handleCameraChange = async () => {
      if (!sceneFirstLoad.current) {
        await simScene.removeEntity(camera.current)
      }
      camera.current = cameraList[camIndex]
      await simScene.addEntity(camera.current)
      if (player.current !== null) {
        camera.current.setTargetEntity(player.current, true, envParams?.camera)
      }
      await simScene.sceneReadyPromise()
    }
    handleCameraChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camIndex])

  const resetCamera = () => camera.current.reset()

  React.useEffect(() => {
    camera.current.blur(sceneIsResetting)
  }, [sceneIsResetting])

  const dispatch = async (action) => {
    validatorController.handleContextEvent(action)

    switch (action.type) {
      // { type: ..., envIndex: Number }
      case SceneActions.ENVIRONMENT_SET: {
        const { envIndex: newEnvIndex } = action
        envIndex.current = newEnvIndex

        let params = simulatedTargetParamsOverride(action.target ?? target, action.params)

        const defaultParams = environmentList[envIndex.current]?.defaultParams
        let newEnvParams = params || defaultParams
        if (params && defaultParams) {
          newEnvParams = merge({}, defaultParams, params)
        }

        setEnvParams(newEnvParams)
        await handleEnvironmentChange(newEnvParams)
        break
      }

      // { type: ... }
      case SceneActions.ENVIRONMENT_RESET:
        resetScore()
        if (!sceneIsResetting) {
          await handleEnvironmentChange(envParams)
        }
        break

      // { type: ..., camIndex: Number }
      case SceneActions.CAMERA_SET:
        const { camIndex } = action
        setCamIndex(camIndex)
        break

      // { type: ... }
      case SceneActions.CAMERA_RESET:
        resetCamera()
        break

      // { type: ..., shouldShow: Boolean }
      case SceneActions.CAMERA_HELP:
        setShowCameraHelp(action.shouldShow)
        if (!action.shouldShow && window.cameraHelpNotify) {
          window.cameraHelpNotify()
        }
        break

      // { type: ..., target: Any }
      case SceneActions.TARGET_DEVICE_SET: {
        // console.log(`Target change from ${target} to ${action.target}`)
        if (target === action.target) {
          // No change
          break
        } else if (action.target.includes('USB') && !usbDebugBackend.enabled) {
          snacks.enqueueSnackbar('Browser does not support USB devices', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
          break
        }

        camera.current.setTargetEntity(null)
        if (player.current !== null && !sceneFirstLoad.current) {
          await simScene.removeEntity(player.current)
          validatorController.setModelController(null)
          player.current = null
        }
        await simScene.sceneReadyPromise()

        backend.disconnectWebTerminal()
        backend.removeCallbacks(validatorController.handleRunStateChange, undefined, undefined, validatorController.handleCodeError)
        backend.removeCallbacks(undefined, undefined, undefined, editorController.addError)
        editorController.breakpointObservable.delete(backend.breakpointChanged)

        let bkend = simDebugBackend
        switch (action.target) {
          case Targets.SIM_CB2:
          case Targets.SIM_CODEX:
            await setScenePlayer(action.target)
            break
          case Targets.USB_CODEBOT:
          case Targets.USB_CODEX:
          case Targets.USB_CODEAIR:
          case Targets.USB_CB2:
          case Targets.USB_CB3:
            bkend = usbDebugBackend
            break
          case Targets.UNCONNECTED:
            break
          default: {
            throw new Error('Unhandled action type')
          }
        }
        bkend.connectWebTerminal(webTerminal)
        bkend.addCallbacks(validatorController.handleRunStateChange, undefined, undefined, validatorController.handleCodeError)
        bkend.addCallbacks(undefined, undefined, undefined, editorController.addError)
        editorController.breakpointObservable.add(bkend.breakpointChanged)
        await bkend.init(action.target)
        setTarget(action.target)
        validatorController.setBackend(bkend)
        setBackend(bkend)
        setDevice(bkend.deviceConnected)
        break
      }

      case SceneActions.TARGET_AND_ENVIRONMENT_SET:
        setSceneIsResetting(true)
        await dispatch({ type: SceneActions.TARGET_DEVICE_SET, target: action.target, requiresSim: action.requiresSim })
        if (!action.requiresSim) {
          setSceneIsResetting(false)
          break
        }
        await dispatch({ type: SceneActions.ENVIRONMENT_SET, envIndex: action.envIndex, params: action.params, target: action.target })
        await dispatch({ type: SceneActions.CAMERA_SET, camIndex: action.camIndex })
        break

      // { type: ..., newVolumeParams: Array }
      case SceneActions.VOLUME_SET:
        const { newVolumeParams } = action
        setVolumeParams(newVolumeParams)
        const env = environmentList[activeEnvIndex.current]
        env.setVolume(audioTaperVolumeParams(newVolumeParams))
        break

      // { type: ..., shouldShow: Bool }
      case SceneActions.VOLUME_SHOW:
        setVolumeShow(action.shouldShow)
        break

      case SceneActions.PLAY_MISSION_COMPLETION_MUSIC:
        try {
          simScene.activeEnvironmentEntity.missionCompleteMusic()
        } catch (err) {
          // console.log(err)
        }
        break

      default:
        throw new Error(`Unhandled action type: ${action.type.toString()}`)
    }
  }

  return (
    <SceneContext.Provider
      value={{
        state: {
          showCameraHelp,
          camIndex,
          envIndex: envIndex.current,
          ready: !sceneIsResetting,
          sceneFirstLoad: sceneFirstLoad.current,
          sceneIsResetting: sceneIsResetting,
          sceneLoading: sceneIsResetting || sceneFirstLoad.current,
          backend,
          device,
          target,
          deviceIsTarget: isDeviceTarget(device, target),
          volumeParams: volumeParams,
          volumeShow,
        },
        dispatch,
      }}>
      {children}
    </SceneContext.Provider>
  )
}

export const useScene = () => {
  const { state, dispatch } = React.useContext(SceneContext)
  if (state === undefined || dispatch === undefined) {
    throw new Error('useScene must be used within a child of a SceneProvider')
  }
  return [state, dispatch]
}
