/* Utilities for use with python-parser.
Primarily this is to provide consistent "normalized strings" that make it easier to author validators against python code.
Currently our CodeParse validator uses the following NPM package: @qoretechnologies/python-parser
https://github.com/qoretechnologies/python-program-analysis appears to derive from https://github.com/microsoft/python-program-analysis

*/

const comma = ', '

// --- Maintaining a Firia Labs version of 'printNode()' since there were some errors in the package (e.g. no spaces around 'binop'). ---
//     Also, since our CodeParse validators depend on the strings NOT changing, we can maintain consistent normalized strings here.
function printTabbed(node, tabLevel) {
  const tabs = ' '.repeat(4 * tabLevel)
  switch (node.type) {
    case 'assert':
      return tabs + 'assert ' + printNode(node.cond)
    case 'assign':
      return (tabs +
                commaSep(node.targets) +
                ' ' +
                (node.op || '=') +
                ' ' +
                commaSep(node.sources))
    case 'binop':
      return '(' + printNode(node.left) + ' ' + node.op + ' ' + printNode(node.right) + ')'
    case 'break':
      return tabs + 'break'
    case 'call':
      return tabs + printNode(node.func) + '(' + node.args.map(printArg) + ')'
    case 'class':
      return (tabs +
                'class ' +
                node.name +
                (node.extends ? '(' + commaSep(node.extends) + ')' : '') +
                ':\n' +
                lines(node.code, tabLevel + 1))
    case 'comp_for':
    case 'comp_if':
      console.log('Parser printNode: comp_for/comp_if not implemented!')
      break
    case 'continue':
      return tabs + 'continue'
    case 'decorator':
      return ('@' +
                node.decorator +
                (node.args ? '(' + commaSep(node.args) + ')' : ''))
    case 'decorate':
      return (tabs +
                lines(node.decorators, tabLevel) +
                printTabbed(node.def, tabLevel))
    case 'def':
      return (tabs +
                'def ' +
                node.name +
                '(' +
                node.params.map(printParam).join(comma) +
                '):\n' +
                lines(node.code, tabLevel + 1))
    case 'dict':
      return '{' + node.entries.map(e => printNode(e.k) + ':' + printNode(e.v)) + '}'
    case 'dot':
      return printNode(node.value) + '.' + node.name
    case 'else':
      return tabs + 'else:\n' + lines(node.code, tabLevel + 1)
    case 'for':
      return (tabs +
                'for ' +
                commaSep(node.target) +
                ' in ' +
                commaSep(node.iter) +
                ':\n' +
                lines(node.code, tabLevel + 1) +
                (node.else ? lines(node.else, tabLevel + 1) : ''))
    case 'from':
      return (tabs +
                'from ' +
                node.base +
                ' import ' +
                node.imports
                  .map(im => im.path + (im.name ? ' as ' + im.name : ''))
                  .join(comma))
    case 'global':
      return tabs + 'global ' + [node.names].join(comma)   // Firia: Added brackets - Grammar bug causes only single name, not array of strings to be produced.
    case 'if':
    case 'elif':
      return (tabs +
                node.type + ' ' +
                printNode(node.cond) +
                ':\n' +
                lines(node.code, tabLevel + 1) +
                (node.elif ?
                  node.elif.map(elif => '\n' + tabs +
                        'elif ' +
                        printNode(elif.cond) +
                        ':\n' +
                        lines(elif.code, tabLevel + 1)) :
                  '') +
                (node.else ? '\n' + tabs + 'else:\n' + lines(node.else.code, tabLevel + 1) : ''))
    case 'ifexpr':
      return (printNode(node.then) +
                ' if ' +
                printNode(node.test) +
                ' else ' +
                printNode(node.else))
    case 'import':
      return (tabs +
                'import ' +
                node.names
                  .map(n => n.path + (n.name ? ' as ' + n.name : ''))
                  .join(comma))
    case 'index':
      return printNode(node.value) + '[' + commaSep(node.args) + ']'
    case 'lambda':
      return ('lambda ' +
                node.args.map(printParam).join(comma) +
                ': ' +
                printNode(node.code))
    case 'list':
      return '[' + node.items.map(item => printNode(item)).join(comma) + ']'
    case 'literal':
      return tabs + (typeof node.value === 'string' && node.value.indexOf('\n') >= 0 ?
        '""' + node.value + '""' :
        node.value.toString())
    case 'module':
      return lines(node.code, tabLevel)
    case 'name':
      return node.id
    case 'nonlocal':
      return tabs + 'nonlocal ' + [node.names].join(comma)   // Firia: Added brackets - Grammar bug causes only single name, not array of strings to be produced.
    case 'raise':
      return tabs + 'raise ' + printNode(node.err)
    case 'return':
      return tabs + 'return ' + (node.values ? commaSep(node.values) : '')
    case 'set':
      return '{' + commaSep(node.entries) + '}'
    case 'slice':
      return ((node.start ? printNode(node.start) : '') +
                ':' +
                (node.stop ? printNode(node.stop) : '') +
                (node.step ? ':' + printNode(node.step) : ''))
    case 'starred':
      return '*' + printNode(node.value)
    case 'try':
      return (tabs +
                'try:\n' +
                lines(node.code, tabLevel + 1) +
                (node.excepts ?
                  node.excepts.map(ex => tabs +
                        'except ' +
                        (ex.cond ?
                          printNode(ex.cond) + (ex.name ? ' as ' + ex.name : '') :
                          '') +
                        ':\n' +
                        lines(ex.code, tabLevel + 1)) :
                  '') +
                (node.else ? tabs + 'else:\n' + lines(node.else, tabLevel + 1) : '') +
                (node.finally ?
                  tabs + 'finally:\n' + lines(node.finally, tabLevel + 1) :
                  ''))
    case 'tuple':
      return '(' + commaSep(node.items) + ')'
    case 'unop':
      return node.op + '(' + printNode(node.operand) + ')'
    case 'while':
      return (tabs +
                'while ' +
                printNode(node.cond) +
                ':\n' +
                lines(node.code, tabLevel + 1))
    case 'with':
      return (tabs +
                'with ' +
                node.items.map(w => (printNode(w.with) + (w.as ? ' as ' + printNode(w.as) : ''))).join(comma) +
                ':\n' +
                lines(node.code, tabLevel + 1))
    case 'yield':
      return (tabs +
                'yield ' +
                (node.from ? printNode(node.from) : '') +
                (node.value ? commaSep(node.value) : ''))
    case 'del':
      return (tabs + 'del')  // No delete target currently captured in AST
    default:
      console.error('Unrecognized parse node type!')
  }
}
function printParam(param) {
  const default_value = param.default_value ?? param.default
  return ((param.star ? '*' : '') +
        (param.starstar ? '**' : '') +
        param.name +
        (default_value ? '=' + printNode(default_value) : '') +
        (param.anno ? printNode(param.anno) : ''))
}
function printArg(arg) {
  return ((arg.kwargs ? '**' : '') +
        (arg.varargs ? '*' : '') +
        (arg.keyword ? printNode(arg.keyword) + '=' : '') +
        printNode(arg.actual) +
        (arg.loop ? ' for ' + arg.loop.for + ' in ' + arg.loop.in : ''))
}
function commaSep(items) {
  return items.map(printNode).join(comma)
}
function lines(items, tabLevel) {
  return items
    .map(i => printTabbed(i, tabLevel))
    .join(tabLevel === 0 ? '\n\n' : '\n') // seperate top-level definitons with an extra newline
}
export function printNode(node) {
  return printTabbed(node, 0)
}
