import authFetchHandler from '../../tools/auth/authFetchHandler'

class UserSessionGateway {
  async deleteUserSession() {
    try {
      await authFetchHandler('session',
        {
          method: 'DELETE',
        })
    } catch (err){
      //
    }
  }

  async updateActivity() {
    try {
      await authFetchHandler('session',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}),
        },
        [500])
    } catch (err){
      //
    }
  }

  async getPing() {
    try {
      const resp = await authFetchHandler('ping',
        {
          method: 'GET',
        })

      return resp.status === 200
    } catch (err){
      //
      console.log(err)
    }
  }
}

const userSessionGateway = new UserSessionGateway()
export { userSessionGateway }