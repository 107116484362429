import { getActiveContentSequenceItem } from './content-manager/content-director/content-director-use-cases/getActiveContentSequenceItem'
import { TargetStates, TargetModes, DebugCommands, TargetInterface } from './TargetInterface'
import { editorController } from './EditorControl'
import { validatorController } from './ValidatorControl'

export default class SimDebugBackend extends TargetInterface {
  constructor(codeRunner) {
    super()
    this.codeRunner = codeRunner
    this.codeRunner.runStateCallbacks.add(this.onCodeRunnerStateChange)
    this.codeRunner.debugStateCallbacks.add(this.onDebugChange)
    this.codeRunner.userProgramErrorCallbacks.add(this.onUserProgramError)
    this.codeRunner.stdoutCallbacks.add(this.onStdoutMsg)
    this.codeRunner.rebootCallbacks.add(this.onReboot)
    this.replRunning = false
    this.autoLaunchRepl = true // We temporarily override this when doing a RESTART DEBUG
    this.code = null
    this.language = ''
  }

  // Because we already use GA4 level_start and level_end events to track "objective progress",
  // using the GA4 level_up event to track their ATTEMPTS seemed a reasonable compromise...
  // We are setting the (numeric) level field to 0 for RUN, 1 for DEBUG
  report_GA4_event = (level) => {
    try {
      const objective = getActiveContentSequenceItem()
      //console.log('objective.flow.id=')
      //console.log(objective.flow.id)
      window.gtag('event', 'level_up', {'level':level,'character':String(objective.flow.id)})
    } catch {
      console.log('GA4 reporting error')
    }
  }

  receiveTextFromTerminal = (text) => {
    this.codeRunner.forwardText(text)
  }

  onStdoutMsg = (text) => {
    // this is the callback to the new validator controller
    // we can eventually remove the above stdout observers if noone is using it
    validatorController.handleConsoleMsg(text)

    // Not planning on using the Stdout observers paradigm here, because this
    // is going to become a fully bidirectional stdio pathway
    if (this.webTerminal?.terminalIO?.print) {
      this.webTerminal.onOutgoingCharacters(text)
    }
  }

  breakpointChanged = () => {
    if (this.lastTargetState === TargetStates.AWAITING_INPUT) {
      const breakpoints = editorController.getBreakpoints()  // Current breakpoint implementation is main file only
      this.codeRunner.updateBreakpoints(breakpoints)
    }
  }

  onCodeRunnerStateChange = (targetState) => {
    this.onStateChange(targetState, true)
  }

  onStateChange = (targetState, fromCodeRunner=false) => {
    if (fromCodeRunner) {
      validatorController.handleRunStateChange(targetState, true)
    }
    // The REPL is actually another Python program, just not one we advertise as such
    if (this.replRunning) {
      // So, these events we discard
      return
    }

    if (this.stateCallbacks.size && targetState !== this.lastTargetState) {
      // console.log(`SimDebug: onStateChange: ${this.lastTargetState}->${targetState}`)
      this.lastTargetState = targetState

      if (targetState === TargetStates.STOPPED) {
        this.doStopActions()
      }

      this.invokeExistingCallbacksOnly(this.stateCallbacks, targetState)
    }
  }

  // { line: int, variables: dictionary }
  onDebugChange = (debugInfo) => {
    // The REPL is actually another Python program, just not one we advertise as such
    if (this.replRunning) {
      // So, these events we discard
      return
    }

    // Getting this event implies that the debugger is waiting on it's next command
    this.onStateChange(TargetStates.AWAITING_INPUT)
    this.reportDebugInfoUpwards(debugInfo)
  }

  reportDebugInfoUpwards = (debugInfo) => {
    if (this.debugCallbacks.size) {
      // TODO Decide at what "level" the following really SHOULD be done at...
      // Fixup filename supplied by Brython if necessary
      if ((debugInfo.file === '<string>') || (debugInfo.file === 'debugger.py')) {
        debugInfo.file = this.filename
      }
      // Note to future developers - one of the callbacks invoked below takes care of making
      // sure the correct editor tab is selected, and highlighting the new current line
      this.invokeExistingCallbacksOnly(this.debugCallbacks, debugInfo)
    }
  }

  onModeChange = (mode) => {
    this.targetMode = mode
    this.invokeExistingCallbacksOnly(this.modeCallbacks, mode)
  }

  onUserProgramError = (err) => {
    if (this.replRunning) {
      // Some Brython errors get past the REPL exception handlers, or crash Brython.
      // Also, CTRL-C generates a KeyboardInterrupt error which is handled here.
      // Display error message to console and restart REPL in these cases.
      if (this.webTerminal?.terminalIO?.print) {
        this.webTerminal.onOutgoingCharacters(`${err.name}: ${err.message}\nRestarting...\n`)
      }
      this.codeRunner.stop()
      this.repl()
    } else {
      if (err.name === 'KeyboardInterrupt') {
        this.stopRun()
      }
      this.invokeExistingCallbacksOnly(this.userProgramErrorCallbacks, err)
    }
  }

  run = async (code, language) => {
    // TODO replace modelReady check with connect/disconnect tracking to prevent run() being called
    if (!this.codeRunner.modelController.modelReady) {
      return
    }
    // stored to allow for reboot to restart code
    this.code = code
    this.language = language
    this.onModeChange(TargetModes.RUN)
    try {
      // TODO Sort out duplication between here and in CodeRunner
      editorController.clearMarkers()
      // TODO Sort out near-duplication between here and in CodeRunner
      this.onStateChange(TargetStates.LOADING)

      // Shut down the REPL (if needed) so we can replace it with the user's code
      if (this.replRunning) {
        this.codeRunner.stop()
        this.replRunning = false
      }

      // Start worker thread and prepare to run code
      await this.codeRunner.start()

      this.report_GA4_event(0) // Google Analytics

      // Run the user's code
      if (language === 'javascript') {
        this.codeRunner.runJS(code)
      } else {
        this.codeRunner.runPython(code)
      }
      this.onStateChange(TargetStates.RUNNING)
    } catch (error) {
      console.error(error)
    }
  }

  // Respond to the STOP RUNNING button
  stopRun = () => {
    if (!this.replRunning) {
      this.codeRunner.stop()
    }
  }

  doStopActions = () => {
    if (this.targetMode === TargetModes.DEBUG) {
      this.reportDebugInfoUpwards({line: -1, debugVariables:null})
      this.onModeChange(TargetModes.RUN)
    }
    // Why don't we ALWAYS auto-launch the REPL? Because we have a RESTART DEBUG
    // button... there is no point spinning up a REPL just to shut it back down.
    if (this.autoLaunchRepl) {
      this.repl() // Because the embedded devices exit to their REPLs
    }
  }

  debug = async (filename, code, language, initialBreakpoints) => {
    // TODO replace modelReady check with connect/disconnect tracking to prevent debug() being called
    if (!this.codeRunner.modelController.modelReady) {
      return
    }
    // The following became necessary once we added multi-tab support
    this.filename = filename
    // The following are kept solely so we can support the RESTART DEBUG button
    this.code = code
    this.language = language
    this.initialBreakpoints = initialBreakpoints

    this.onModeChange(TargetModes.DEBUG)
    this.onStateChange(TargetStates.LOADING)
    editorController.clearMarkers()
    editorController.setDebugModel()
    const breakpoints = editorController.getBreakpoints()  // Current breakpoint implementation is main file only

    this.onStateChange(TargetStates.AWAITING_INPUT) // TODO Should this be TargetStates.RUNNING?

    try {
      // Shut down the REPL (if needed) so we can replace it with the user's code
      if (this.replRunning) {
        this.codeRunner.stop()
        this.replRunning = false
      }

      // Start worker thread and prepare to run code
      await this.codeRunner.start()

      this.report_GA4_event(1) // Google Analytics


      // Run the user's code
      // TODO It may be better to save code into a VFS file
      // Currently we get inconsistent reported filenames. Sometimes it is '<string>' and
      // sometime Brython reports 'debugger'. Technically, neither one is correct...
      this.codeRunner.runPython('from debugger import debug_filestr\ndebug_filestr("""' + code.replaceAll('"""', '\'\'\'') + '""")\n', breakpoints)
    } catch (error) {
      console.error(error)
    }
  }

  debugCommands = (debugCommand) => {
    if (debugCommand === DebugCommands.STOP) {
      this.stopDebug() // which will also pass the STOP command along to this.codeRunner
    } else if (debugCommand === DebugCommands.RESTART) {
      this.restartDebug() // this is essentially pressing STOP and DEBUG again for you
    } else {
      this.codeRunner.debugCommands(debugCommand) // STEP_INTO, STEP_OVER, STEP_OUT, etc.
      this.onStateChange(TargetStates.RUNNING, false)
    }
  }

  // Respond to the STOP DEBUGGING button
  stopDebug = () => {
    this.codeRunner.stop()
  }

  // Respond to the RESTART DEBUGGING button
  restartDebug = () => {
    this.autoLaunchRepl = false
    this.stopDebug()
    this.autoLaunchRepl = true
    this.debug(this.filename, this.code, this.language, this.initialBreakpoints)
  }

  repl = async (firstTime=false) => {
    this.replRunning = true
    await this.codeRunner.start(firstTime)
    this.codeRunner.runPython('from repl import repl\nrepl(banner="CodeSpace {version} type help() for more information.")\n')
  }

  init = async (deviceType) => {
    // Called to initialize debugBackend when target is changed
    this.deviceConnected = deviceType
    this.deviceConnCb()
    if (this.replRunning) {
      // Kill previous repl()
      this.replRunning = false
      this.codeRunner.stop()
    }
    this.repl()
  }

  onReboot = () => {
    this.codeRunner.stop()
    // TODO: maybe add a delay here to simulate the actual bot
    if (this.code === null) {
      this.repl()
    } else {
      this.run(this.code, this.language)
    }
  }
}
