/* USB Debug Backend - implement debug interface to serial devices

Debugger Commands are:
?/h/help - display this info
w/where - display the block, file and line number of the current line
s/step - execute one line (step into subroutines)
n/next - execute one line (step over subroutines)
r/return - execute until current subroutine ends (step out of subroutines)
c/cont/continue - continue running program
d/dump - display the local variables
g[0-999] - display block N from the total set of global variables
ba [file] line - add a breakpoint
bd [file] line - remove a breakpoint
bl - display all breakpoints
bc - clear all breakpoints
!<python goes here> - force rest of command line to be interpreted as Python
*/

import { TargetStates, TargetModes, DebugCommands, TargetInterface } from './TargetInterface'
import { editorController } from './EditorControl'
import { webUsbController } from './WebUsbControl'
import { validatorController } from './ValidatorControl'
import { Targets } from './Players'
import fetchHandler from './tools/tracking/fetchHandler'
import { getActiveMissionPack } from './content-manager/content-director/content-director-use-cases/getActiveMissionPack'

const CTL_ENTER_RAW = 1
const CTL_ENTER_RAW_CHR = String.fromCharCode(CTL_ENTER_RAW)
const CTL_EXIT_RAW = 2
const CTL_EXIT_RAW_CHR = String.fromCharCode(CTL_EXIT_RAW)
const CTL_BREAK = 3
const CTL_BREAK_CHR = String.fromCharCode(CTL_BREAK)
const CTL_FINISH = 4
const CTL_FINISH_CHR = String.fromCharCode(CTL_FINISH)

// REPL embedded escape sequences delimiting debugger output
const REPL_ESC_DEBUG = '\xC2'
const REPL_ENTER_DEBUG = '\xAB'
const REPL_EXIT_DEBUG = '\xBB'
const REPL_VAR_PREFIX = '\xB7'

// Console filter states
const CF_STATE_ON = 0
const CF_STATE_ON_ESC = 1
const CF_STATE_OFF = 2
const CF_STATE_OFF_ESC = 3
const CF_STATE_NO_ECHO = 4

const TRACEBACK_BUF_SIZE = 500

const tracebackRegEx = /(?:Traceback \(most recent call last\):)$\s+File(?:.*$\s+File)* "(.+)", line (\d+),?.*\r*\n(\S*:.*)\r*\n/gm

const varsRegEx = /(.*):\xc2\xb7(?:\xc2\xab)*(?:\xc2\xbb)*(.*)\xc2\xb7(.*)/
const localsRequestRegEx = /<<< d/
const globalsRequestRegEx = /<<< g(\d+)/
// eslint-disable-next-line
const replPromptRegEx = /\x0A\x3E\x3E\x3E\x20/gm
// eslint-disable-next-line
const debugPromptRegEx = /\x0A\x3C\x3C\x3C\x20/gm
const unableToSetBkptRegEx = /ba (\d+)\s*Not an executable source line/gm
const terminatedExprRegEx = /debugger disarmed/gm

const hwVerDataLink = 'https://upgrade.firialabs.com/versions.json'

// Globals not to be shown in variables panel
const ignoreVars = ['__file__', '__name__']

// Globals to be inserted under the corresponding library module
const libVars = {
  codex: [
    'GREEN', 'BLACK', 'BLUE', 'RED', 'WHITE', 'BROWN', 'ORANGE', 'CYAN', 'DARK_BLUE', 'DARK_GREEN', 'LIGHT_GRAY', 'MAGENTA',
    'PINK', 'PURPLE', 'remote', 'pixels', 'power', 'YELLOW', 'display', 'exp', 'GRAY', 'lcd', 'leds', 'LED_A', 'LED_B',
    'light', 'BTN_A', 'BTN_B', 'BTN_U', 'BTN_D', 'BTN_L', 'BTN_R', 'buttons', 'accel', 'ALL_COLORS', 'audio', 'backlight',
    'tft', 'COLORS_BY_NAME', 'TRANSPARENT', 'console', 'COLOR_LIST',
  ],
  botcore: ['accel', 'ADC_FULL_SCALE', 'buttons', 'enc', 'exp', 'leds', 'LEFT', 'ls', 'motors', 'prox', 'RIGHT', 'spkr', 'system'],

}

// Library modules per device (where libVars are found)
const findLib = {
  USB_CODEX: 'codex',
  USB_CODEAIR: 'codeair',
  USB_CB2: 'botcore',
  USB_CB3: 'botcore',
}

class VariableWatcher {
  constructor(usb) {
    this.locals = []
    this.globals = []
    this.buf = ''
    this.usb = usb
    this.lastGlobalNum = 0
    this.expectingGlobals = false
    this.globalsChanged = false
    this.localsChanged = false
  }

  check = (buf) => {
    // This function is handed fragmented serial rx data
    this.varsBuf += buf

    // If complete line(s) are found, process line at a time
    if (this.varsBuf.includes('\r')) {
      this.globalsChanged = false
      this.localsChanged = false
      const lastReturn = this.varsBuf.lastIndexOf('\r')
      const lines = this.varsBuf.slice(0, lastReturn + 1).split('\r')
      lines.forEach(line => this.processLine(line))
      this.varsBuf = this.varsBuf.slice(lastReturn + 1)
    }

    // If we're waiting at debug prompt...
    if (this.varsBuf.slice(-4) === '<<< ') {
      // Are there more globals coming? Got full count from prior gX requests; not at g8 limit;
      if (this.globalsChanged && this.lastGlobalNum < 9 && this.globals.length === (this.lastGlobalNum + 1) * 8) {
        this.usb.sendDebuggerCmd(`g${this.lastGlobalNum + 1}\r`, true)
      } else if ((!this.globalsChanged && this.lastGlobalNum < 9 && this.globals.length === this.lastGlobalNum * 8) || this.globalsChanged) {
        const debugInfo = this.normalize()
        this.usb.onDebugChange(debugInfo, true)
      }

      this.globalsChanged = false
      this.localsChanged = false
    }
  }

  normalize = () => {
    // Convert this.locals[] and this.globals[] to filtered object mappings of name:val
    const debugInfo = {
      variables: {
        Locals: {},
        Globals: {},
      },
    }
    if (this.usb.deviceConnected !== Targets.UNCONNECTED){
      // If a device is connected we automatically insert the 'botcore' or 'codex' lib
      debugInfo.variables.Globals[findLib[this.usb.deviceConnected]] = {pyValType: 'firiaModule', value: {}}
    }
    let i = 0
    // Build object mapping local vars name:val
    for (i = 0; i < this.locals.length; i++) {
      debugInfo.variables.Locals[this.locals[i].name] = this.locals[i].val
    }
    // Build Globals object mapping, also filter 'ignoreVars'
    for (i = 0; i < this.globals.length; i++) {
      if (ignoreVars.includes(this.globals[i].name)) {
      } else if (this.usb.deviceConnected !== Targets.UNCONNECTED && libVars[findLib[this.usb.deviceConnected]].includes(this.globals[i].name)) {
        // Insert members of 'libVars' under the corresponding (botcore or codex) library module
        debugInfo.variables.Globals[findLib[this.usb.deviceConnected]].value[this.globals[i].name] = this.globals[i].val
      } else {
        debugInfo.variables.Globals[this.globals[i].name] = this.globals[i].val
      }
    }
    return debugInfo
  }

  processLine = (line) => {
    const lineExpr = /block=(.*?) file=(.*?) line=(\d+)/
    let m = lineExpr.exec(line)
    if (m && (m[2] === '__main__' || m[2] === 'main.py')) {
      let debugInfo = {
        line: parseInt(m[3], 10),
        name: m[1],
        // file : m[2], // if it is main.py just leave it missing
      }
      this.usb.onDebugChange(debugInfo)
    }

    localsRequestRegEx.lastIndex = 0
    if (localsRequestRegEx.test(line)) {
      this.locals = []
      this.expectingGlobals = false
      this.usb.sendDebuggerCmd('g0\r', true)
    } else {
      globalsRequestRegEx.lastIndex = 0
      m = globalsRequestRegEx.exec(line)
      if (m) {
        this.lastGlobalNum = parseInt(m[1], 10)
        if (this.lastGlobalNum === 0) {
          this.globals = []
        }
        this.expectingGlobals = true
        // this.setMbState(mb.state.RUNNING)
      }
    }

    varsRegEx.lastIndex = 0
    m = varsRegEx.exec(line)
    if (m) {
      let vars = this.locals
      if (this.expectingGlobals) {
        vars = this.globals
        this.globalsChanged = true
      } else {
        this.localsChanged = true
      }

      vars.push({
        name: m[1],
        val: {
          value: m[2],
          pyValType: m[3],
          strValue: m[2].toString(),
        },
      })
    }
  }
}

export default class UsbDebugBackend extends TargetInterface {
  constructor() {
    super()

    this.enabled = webUsbController.enabled
    if (!this.enabled) {
      return
    }

    this.deviceData = {
      USB_CODEX: {
        bootRegex: /FiriaLabs IoT Python (.*) on ([\d-]*); ([0-9a-fA-F]+).* (CodeX \S*) with (\S*)/gm,
        hwVer: null,
        numBreakpoints: 16,
        intCmd: '\x02\x03', // Exit raw, ^C
        verId: 'codex',
        displayName: 'CodeX',
      },
      USB_CODEAIR: {
        bootRegex: /FiriaLabs IoT Python (.*) on ([\d-]*); ([0-9a-fA-F]+).* (CodeAIR \S*) with (\S*)/gm,
        hwVer: null,
        numBreakpoints: 16,
        intCmd: '\x02\x03', // Exit raw, ^C
        verId: 'codeair',
        displayName: 'CodeAIR',
      },
      USB_CB2: {
        bootRegex: /MicroPython ([0-9a-fA-F]+).* on ([\d-]*); ([0-9a-fA-F]+).* (\S*) with (\S*)/gm,
        hwVer: null,
        numBreakpoints: 16,
        intCmd: '\x02\x03c\r', // Exit raw, ^C, 'c' to continue debugger, return for prompt
        verId: 'cb2',
        displayName: 'CodeBot',
      },
      USB_CB3: {
        bootRegex: /FiriaLabs IoT Python (.*) on ([\d-]*); ([0-9a-fA-F]+).* (CodeBot \S*) with (\S*)/gm,
        hwVer: null,
        numBreakpoints: 16,
        intCmd: '\x02\x03', // Exit raw, ^C
        verId: 'cb3',
        displayName: 'CodeBot',
      },
    }

    this.getHwVerData()  // Fetch device versions from cloud
    this.varsWatcher = new VariableWatcher(this)

    this.consoleFilterState = CF_STATE_ON
    this.consoleFilterDebugRecurse = 0
    this.bootRegEx = /^/gm

    this.deviceConnected = Targets.UNCONNECTED
    this.deviceVersionInfo = null

    // initialize on first load to prevent USB connection and state issues
    this.init(Targets.USB_CODEX)
  }

  init = async (target) => {
    this.devicePlatform = target
    if (this.deviceConnected === Targets.UNCONNECTED) {
      webUsbController.dataReceivedCb = this.handleSerialChars
      webUsbController.deviceConnectedCb = this.onDeviceConnected
      webUsbController.deviceDisconnectedCb = this.onDeviceDisconnected
      await webUsbController.disconnect()
      await webUsbController.connectAvailable()
    } else {
      this.stopRun()
    }
  }

  getHwVerData = async () => {
    // Fetch device versions from cloud.
    try {
      const resp = await fetchHandler(hwVerDataLink, {'cache': 'no-store'})
      const data = await resp.json()
      for (const key in this.deviceData) {
        // First version in list is guaranteed to be the latest version.
        const deviceObj = data[this.deviceData[key].verId]
        if (!!deviceObj) {
          this.deviceData[key].hwVer = data[this.deviceData[key].verId].versions[0].sha
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  receiveTextFromTerminal = async (text) => {
    if (text === '\x03') {
      this.stopRun()
    } else {
      // Filter the echo of command we're about to send
      this.consoleFilterState = CF_STATE_NO_ECHO
      this.echoFilter = text + '\r'
      await this.sendCommand(text + '\r', />>>/, false)
    }
  }

  breakpointChanged = () => {
    if (this.lastTargetState === TargetStates.AWAITING_INPUT && this.targetMode === TargetModes.DEBUG) {
      this.sendAllBreakpoints()
    }
  }

  onModeChange = (mode) => {
    // console.log(`ModeChange: ${TargetModes[this.targetMode]} -> ${TargetModes[mode]}`)
    this.targetMode = mode
    this.invokeExistingCallbacksOnly(this.modeCallbacks, mode)
  }

  onStateChange = (targetState) => {
    // console.log(`StateChange: ${TargetStates[this.lastTargetState]} -> ${TargetStates[targetState]}`)
    if (this.stateCallbacks.size && targetState !== this.lastTargetState) {
      this.lastTargetState = targetState
      this.invokeExistingCallbacksOnly(this.stateCallbacks, targetState)
    }
  }

  // { line: int, variables: dictionary }
  onDebugChange = (debugInfo, variablesOnly=false) => {
    if (!variablesOnly && this.targetMode === TargetModes.DEBUG) {
      this.onStateChange(TargetStates.AWAITING_INPUT)
      this.sendDebuggerCmd('d\r', true)
    }

    this.invokeExistingCallbacksOnly(this.debugCallbacks, debugInfo)
  }

  onDeviceConnected = (deviceName) => {
    this.tracebackBuffer = ''
    this.echoFilter = ''
    this.deviceConnected = deviceName
    this.bootRegEx = this.deviceData[this.deviceConnected].bootRegex
    this.stopRun()
    this.onModeChange(TargetModes.REPL)
    this.deviceConnCb()
  }

  onDeviceDisconnected = () => {
    this.deviceConnected = Targets.UNCONNECTED
    this.showedUpgradeNotice = false
    this.deviceVersionInfo = null
    this.stopRun()
    this.onModeChange(TargetModes.DISCONNECTED)
    this.deviceConnCb()
  }

  copyPythonFile = async (filename) => {
    // Called after download saves current file as 'main.py'. We want to allow 'import' if the user
    // has named the file properly, so we need to save it by the correct name. This is an MVP approach
    // to multi-file support.
    // Is currentFilename a proper Python filename?
    const match = filename && filename.match(/^[a-zA-Z_]\w*\.py$/)

    if (match) {
      // Copy main.py to proper filename (copy src->dest in chunks)
      // Allow extra time for file copy. Measured about 250ms for 10k file. Conservatively allow 1000ms per 10k.
      const copyTimeout = 4000 + Math.round(this.lastLoadedCode.length / 10)
      await this.sendCommand(`d=open("${filename}","w")`)
      await this.sendCommand('s=open("main.py")')
      await this.sendCommand('while d.write(s.read(512)): pass\r', />/, true, copyTimeout)
      await this.sendCommand('d.close()')
    }
  }

  download = async (destFilename, code) => {
    let fileBuf = code
    // this.lastLoadedCode = fileBuf
    fileBuf = fileBuf.replace(/'{3}/gm, '"""')  // Replace all triple single quotes with triple double quotes
    fileBuf = fileBuf.replace(/\r\n/gm, '\n')
    // Verify we're in stop state; wait for prompt
    await this.sendCommand('\r', />>>/, false)
    // Open file and init write shortcut
    await this.sendCommand(CTL_ENTER_RAW_CHR)
    await this.sendCommand(`fd=open("${destFilename}", "wb")`)
    // TODO: Changed so all platforms use 'wb'. Test on CB2 to ensure encode('utf-8') below is supported.
    // if (this.devicePlatform === Targets.USB_CB2) {
    //   await this.sendCommand(`fd=open("${destFilename}", "wb")`)
    // } else {
    //   await this.sendCommand(`fd=open("${destFilename}", "w")`)
    // }
    await this.sendCommand('w=fd.write')

    const chunksize = 1024
    for (let i = 0; i < fileBuf.length; i += chunksize) {
      let chunk = fileBuf.substr(i, chunksize)

      chunk = chunk.replace(/\\/g, '\\x5c')  // literal backslash

      if (chunk.slice(-1) === '\'') {
        // If the last character is a single quote then escape it since we are about to wrap it in triple single quotes
        chunk = chunk.slice(0, -1) + '\\\''
      }

      // Using triple single quotes to minimize what needs to be escaped
      await this.sendCommand(`w('''${chunk}'''.encode('utf-8'))`)
    }

    await this.sendCommand('fd.close()')

    if (destFilename !== 'main.py') {
      await this.copyPythonFile(destFilename)
    }

    // Reset device
    if (this.devicePlatform === Targets.USB_CB2) {
      // Rewrite boot.py in case of flash corruption. This will no longer be necessary when we:
      // a) Implement multifile support with file-integrity checks, and/or b) Fix flash corruption issues.
      await this.sendCommand('fd=open("boot.py", "wb")')
      await this.sendCommand('fd.write("import pyb\\nc=None")')
      await this.sendCommand('fd.close()')
      await this.sendCommand('pyb.sync()')  // Write file to FLASH
    } else {
      // I don't know if the following is necessary or not.
      // I am only doing it because the CB2 code invokes pyb.sync()
      await this.sendCommand('from os import sync')
      await this.sendCommand('sync()')  // Write file to FLASH
    }

    await this.sendCommand(CTL_EXIT_RAW_CHR)
    await webUsbController.send(CTL_FINISH_CHR)   // Soft reboot  (may not get prompt if looping)
    await this.sendCommand('\r', /<<</, false)

    // Set console filter state directly since we will have missed the REPL_ENTER_DEBUG flag.
    this.consoleFilterState = CF_STATE_OFF
    this.consoleFilterDebugRecurse = 0
  }

  sendAllBreakpoints = async () => {
    this.breakpoints = editorController.getBreakpoints()
    await this.sendCommand('bc\r', /<<</, false)  // Clear all breakpoints
    for (let i = 0; i < this.breakpoints.length; i++) {
      await this.sendCommand(`ba ${this.breakpoints[i]}\r`, /<<</, false)  // Add breakpoint to 'main.py'
    }
  }

  load = async (code) => {
    this.tracebackBuffer = ''
    this.accumulatedBuf = ''

    // Download and reset to start
    try {
      await this.download('main.py', code)
    } catch (error) {
      // Enable specific error handling behavior
      if (error === 'Traceback error') {
        // The traceback error callback is already being invoked, so there is no reason to invoke it here
      } else {
        // Display download failed error, only if we don't have a more specific error to display
        // this.onDownloadFailed()
        console.log('Download Failed', { error: error, rxBuf: window.firiaSerialRxDebug })
      }
      await webUsbController.send(CTL_BREAK_CHR)   // ^C may be needed to exit triple-quotes
      this.stopRun() // check that this is the right thing
    }
  }

  sendDebuggerCmd = async (cmd, internal = false) => {
    // console.log("sendDebuggerCmd: ", cmd)
    await webUsbController.send(cmd)
  }

  run = async (code, language) => {
    // TODO check if CB2 is ready to accept commands

    this.onModeChange(TargetModes.RUN)
    try {
      editorController.clearMarkers()
      this.onStateChange(TargetStates.LOADING)

      await this.load(code)
      await this.sendDebuggerCmd('c\r') // Continue cmd actually runs the code

      this.onStateChange(TargetStates.RUNNING)
    } catch (error) {
      console.error(error)
    }
  }

  sendCommand = async (cmd, expect = />/, finish = true, timeout = 4000) => {
    // If cmd is a non-control character, append a "finish"
    if (finish && cmd.charCodeAt(0) > 9) {
      cmd += CTL_FINISH_CHR
    }
    try {
      await webUsbController.send(cmd)
    } catch (e) {
      console.log('Web USB Controller send failure')
    }
  }

  KeyboardInterrupt = () => {
    if (this.deviceConnected !== Targets.UNCONNECTED) {
      this.sendDebuggerCmd(this.deviceData[this.deviceConnected].intCmd)
    }
  }

  // Respond to the STOP RUNNING button
  stopRun = async () => {
    if (this.targetMode === TargetModes.DEBUG) {
      this.invokeExistingCallbacksOnly(this.debugCallbacks, {line: -1, variables: {Locals: {}, Globals: {}}})
    }
    // CodeBot only requires this sequence, AND experiences FATAL unhandled exception errors when you
    // issue a stop() while single-stepping if you do the full sequence with 'c\r' appended.
    // TODO: Investigate the FATAL error case...

    // Send ^C repeatedly since an unexpected reboot can put device in debug mode for a period of time,
    // especially since 'stopRun' is used in onDeviceConnected() and otherwise can fail to elicit REPL
    const max_retries = 5
    const timeout = 1000  // ms
    for (let i = 0; i < max_retries; ++i) {
      this.KeyboardInterrupt()
      try {
        await(new Promise((resolve, reject) => {
          const tm = setTimeout(reject, timeout)
          this.gotReplPrompt = () => {
            // console.log('got REPL!')
            clearTimeout(tm)
            resolve()
          }
        }))
        break  // success!
      } catch (err) {
        // console.log('timeout waiting on REPL, retrying...')
      }
    }

    this.onModeChange(TargetModes.REPL)
    this.onStateChange(TargetStates.STOPPED)
  }

  debug = async (filename, code, language, initialBreakpoints) => {
    if (this.deviceConnected === Targets.UNCONNECTED) {
      return
    }
    // TODO check with connect/disconnect tracking to prevent debug() being called
    // The following became necessary once we added multi-tab support
    this.filename = filename
    // The following are kept solely so we can support the RESTART DEBUG button
    this.code = code
    this.initialBreakpoints = initialBreakpoints

    this.onModeChange(TargetModes.DEBUG)
    this.onStateChange(TargetStates.LOADING)

    editorController.clearMarkers()
    editorController.setDebugModel()

    await this.load(code)
    this.sendAllBreakpoints()
    this.sendDebuggerCmd('w\r') // step into

    this.onStateChange(TargetStates.AWAITING_INPUT) // TODO Should this be TargetStates.RUNNING?
  }

  debugCommands = (debugCommand) => {
    // console.log(debugCommand)

    switch (debugCommand) {
      case DebugCommands.CONTINUE: {
        this.onStateChange(TargetStates.AWAITING_INPUT)
        this.sendDebuggerCmd('c\r')
        this.onStateChange(TargetStates.RUNNING)
        break
      }
      case DebugCommands.PAUSE: {
        // TODO: right now there is no pause insdie the physical devices
        this.KeyboardInterrupt()
        this.onModeChange(TargetModes.REPL)
        this.onStateChange(TargetStates.STOPPED)
        break
      }
      case DebugCommands.STEP_OVER: {
        this.sendDebuggerCmd('n\r')
        this.onStateChange(TargetStates.RUNNING)
        break
      }
      case DebugCommands.STEP_INTO: {
        if (this.targetMode === TargetModes.REPL && this.lastTargetState === TargetStates.AWAITING_INPUT) {
          this.sendDebuggerCmd('w\r')
        } else if (this.targetMode === TargetModes.DEBUG && this.lastTargetState === TargetStates.AWAITING_INPUT) {
          this.sendDebuggerCmd('s\r')
        }
        this.onStateChange(TargetStates.RUNNING)
        break
      }
      case DebugCommands.STEP_OUT: {
        this.sendDebuggerCmd('r\r')
        this.onStateChange(TargetStates.RUNNING)
        break
      }
      case DebugCommands.RESTART: {
        this.restartDebug()
        break
      }
      case DebugCommands.STOP: {
        this.stopDebug()
        break
      }
      default: {
        throw new Error('Unhandled action type')
      }
    }
  }

  checkVersion = () => {
    if (this.deviceConnected === Targets.UNCONNECTED) {
      return
    }

    if (!this.deviceVersionInfo?.ver || !this.deviceVersionInfo?.date) {
      return
    }

    const activePack = getActiveMissionPack()
    if (!activePack || !activePack?.targetFirmware) {
      return
    }

    // If the connected device isn't used in the pack we don't need to version check.
    let packTargetFirmwareDateStr = activePack?.targetFirmware[this.deviceConnected]
    if (!packTargetFirmwareDateStr) {
      return
    }

    if (packTargetFirmwareDateStr !== '*' && new Date(packTargetFirmwareDateStr) <= new Date(this.deviceVersionInfo.date)) {
      return
    }

    const ver = this.deviceData[this.deviceConnected].hwVer
    const needsUpgrade = !this.deviceVersionInfo.ver || (ver && !this.deviceVersionInfo.ver.includes(ver)) // Assume okay if failed to fetch online version record
    if (needsUpgrade && !this.showedUpgradeNotice) {
      // console.log('device needs upgrade')
      this.showedUpgradeNotice = true
      this.upgradeDeviceCb(this.deviceConnected, this.deviceData[this.deviceConnected].displayName)
    }
  }

  // Track state of serial data stream - from debugger vs REPL/stdout
  trackDebugVsRepl = (strBuf) => {
    /* Note that depending on the speed of the computer, multiple messages can be received in a single buffer
    payload, therefore it is required that we recurse through the buffer until the LAST message has been
    identified. Otherwise the order that we check them will influence the current state.
    */
    this.accumulatedBuf += strBuf
    // console.log(`--ACCUM_BUF--\n${this.accumulatedBuf}\n--END--`)
    let regExMatch = false

    // CircuitPython introduces an additional prompt instead of going directly into the REPL
    // Go ahead and press ENTER so the usual startup banner will appear
    // const replOfferRegEx = /Press any key to enter the REPL. Use CTRL-D to reload./gm
    // const replOfferMatch = replOfferRegEx.exec(this.accumulatedBuf)
    // if (replOfferMatch) {
    //   this.sendDebuggerCmd('\r', true)
    //   regExMatch = true
    //   this.accumulatedBuf = this.accumulatedBuf.slice(0, replOfferMatch.index) + this.accumulatedBuf.slice(replOfferRegEx.lastIndex)
    //   this.onModeChange(TargetModes.REPL)
    //   this.onStateChange(TargetStates.STOPPED)
    // }

    const codeDoneRegEx = /Code done running./gm
    const codeDoneMatch =codeDoneRegEx.exec(this.accumulatedBuf)
    if (codeDoneMatch) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, codeDoneMatch.index) + this.accumulatedBuf.slice(codeDoneRegEx.lastIndex)

      // Note: Duplicated in stopRun() -- TODO: consolidate this.
      if (this.targetMode === TargetModes.DEBUG) {
        this.invokeExistingCallbacksOnly(this.debugCallbacks, {line: -1, variables: {Locals: {}, Globals: {}}})
      }

      this.onModeChange(TargetModes.REPL)
      this.onStateChange(TargetStates.STOPPED)
    }

    /*
    const stoppedExpr = /MP-Complete$/gm
    m = stoppedExpr.exec(this.accumulatedBuf)
    if (m) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, m.index) + this.accumulatedBuf.slice(stoppedExpr.lastIndex)
      this.setStopped(true)
      this.setDebugCursor(null)
    }
    */

    this.bootRegEx.lastIndex = 0
    // Groups: 1)hash, 2)date, 3)platform, 4)CPU
    // E.g. "MicroPython 64453a93a on 2019-05-01; 4F126609 CODEBOT_CB2 with STM32L476"
    //                      1^             2^         3^        4^             5^
    const bootMatch = this.bootRegEx.exec(this.accumulatedBuf)
    if (bootMatch) {
      this.deviceVersionInfo = { ver: bootMatch[1], date: bootMatch[2] }
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, bootMatch.index) + this.accumulatedBuf.slice(this.bootRegEx.lastIndex)
      this.checkVersion()
    }

    unableToSetBkptRegEx.lastIndex = 0
    const unableToSetBkptMatch = unableToSetBkptRegEx.exec(this.accumulatedBuf)
    if (unableToSetBkptMatch) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, unableToSetBkptMatch.index) + this.accumulatedBuf.slice(unableToSetBkptRegEx.lastIndex)
      // console.log("Unable to set breakpoint", unableToSetBkptMatch)
      // const line = parseInt(unableToSetBkptMatch[1], 10)
      // TODO: Popup "toast" warning "Breakpoint removed from non-executable line"
      // editorInst.session.clearBreakpoint(line - 1)
      // editorInst.session.addGutterDecoration(line - 1, "invalid-breakpoint")
      // Notify Ace and observers of potential changes.
      // editorInst.session._signal("changeBreakpoint", {})
    }

    // Debugger terminated - we are running!
    terminatedExprRegEx.lastIndex = 0
    const terminatedExprMatch = terminatedExprRegEx.exec(this.accumulatedBuf)
    if (terminatedExprMatch) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, terminatedExprMatch.index) + this.accumulatedBuf.slice(terminatedExprRegEx.lastIndex)
      // this.setStopped(false)
      // this.setMbState(mb.state.RUNNING)
      // this.hiddenDebugCursor = this.curDebugCursor
      // this.setDebugCursor(null)
    }

    // Got REPL prompt >>>
    replPromptRegEx.lastIndex = 0
    const replMatch = replPromptRegEx.exec(this.accumulatedBuf)
    if (replMatch) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, replMatch.index) + this.accumulatedBuf.slice(replPromptRegEx.lastIndex)
      if (!terminatedExprMatch || replMatch.index > terminatedExprMatch.index) {
        if (this.gotReplPrompt) {
          this.gotReplPrompt()
        }
      }
    }

    debugPromptRegEx.lastIndex = 0
    const debugMatch = debugPromptRegEx.exec(this.accumulatedBuf)
    if (debugMatch) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, debugMatch.index) + this.accumulatedBuf.slice(debugPromptRegEx.lastIndex)
      if ((!terminatedExprMatch || debugMatch.index > terminatedExprMatch.index) &&
        (!replMatch || debugMatch.index > replMatch.index)) {
        // this.setStopped(false)
        // this.setMbMode(mb.mode.DEBUG)
        // this.setMbState(mb.state.IDLE)
        // if (!this.curDebugCursor && this.hiddenDebugCursor) {
        //  this.setDebugCursor(this.hiddenDebugCursor)
        // }
      }
    }

    // Make sure that we handle multiple (of the same message) in the buffer within a single call
    // to trackDebugVsRepl
    if (regExMatch) {
      this.accumulatedBuf = this.accumulatedBuf.slice(1)
      if (this.accumulatedBuf) {
        // console.log("More accumulated", this.accumulatedBuf)
        this.trackDebugVsRepl('')
      }
    }
    // console.log(`--mbMode=${mb.getModeName(this.mbMode)}, mbState=${mb.getStateName(this.mbState)}, isStopped=${this.isStopped}--`)
  }



  // Respond to the STOP DEBUGGING button
  stopDebug = () => {
    this.stopRun()
  }

  // Respond to the RESTART DEBUGGING button
  restartDebug = () => {
    this.stopDebug()
    this.debug(this.filename, this.code, 'python', this.initialBreakpoints)
  }

  checkForTraceback = (strBuf) => {
    this.tracebackBuffer += strBuf

    // If multiple tracebacks exist, process only the first one
    if (this.tracebackBuffer.includes('Traceback')) {
      let firstTracebackPos = this.tracebackBuffer.indexOf('Traceback')
      let secondTracebackPos = this.tracebackBuffer.indexOf('Traceback', firstTracebackPos + 9)
      let tempBuffer = ''
      if (secondTracebackPos > 0) {
        tempBuffer = this.tracebackBuffer.slice(firstTracebackPos, secondTracebackPos)
      } else {
        tempBuffer = this.tracebackBuffer.slice(firstTracebackPos)
      }

      tracebackRegEx.lastIndex = 0
      const m = tracebackRegEx.exec(tempBuffer)
      const isReadOnlyFS = m?.[3].includes('Read-only filesystem')

      if (m !== null && (isReadOnlyFS || m[1] !== '<stdin>') && m[3].indexOf('KeyboardInterrupt') === -1) {
        let filename = m[1]
        let lineNum = m[2]
        let err_msg = m[3]

        if (isReadOnlyFS) {
          err_msg = `${err_msg}\nRESET your ${ this.deviceData[this.deviceConnected].displayName} to exit read-only mode.`
        } else if (filename !== '__main__' && filename !== 'main.py') {
          // Append point-of-error filename and line number to message
          err_msg = `${err_msg}\n (${filename} line ${lineNum})`

          // Find last instance of 'main' module in traceback, and set line_number there.
          const traceStackEntryRegEx = /File "(__main__|main\.py)", line (\d*)/gm
          const traceArray = [...tempBuffer.matchAll(traceStackEntryRegEx)]
          lineNum = traceArray?.[traceArray.length - 1]?.[2] ?? '1'
        }

        let errParts = err_msg.split(':')
        const errName = errParts[0]
        errParts.shift()
        const msg = errParts.join(':')


        let err = {
          module: '',
          name: errName,
          lineNumber: parseInt(lineNum, 10),
          message: msg.trim(),
          callStack: null,
          column: 0,
        }

        this.tracebackBuffer = ''

        this.invokeExistingCallbacksOnly(this.userProgramErrorCallbacks, err)
        this.stopRun()
      }
    }

    if (this.tracebackBuffer.length > TRACEBACK_BUF_SIZE) {
      this.tracebackBuffer = this.tracebackBuffer.substring(this.tracebackBuffer.length - TRACEBACK_BUF_SIZE)
    }
  }

  // Filter debugger-specific output from console buffer (prior to sending to ConsoleRepl)
  filterConsole = (buf) => {
    // Expect to be called on fragments of debugger output stream, so run bytewise state machine.
    let outBuf = ''
    for (let i = 0; i < buf.length; i++) {
      const c = buf[i]
      if (this.consoleFilterState === CF_STATE_ON) {
        if (c === REPL_ESC_DEBUG) {
          this.consoleFilterState = CF_STATE_ON_ESC
        } else {
          outBuf += c
        }
      } else if (this.consoleFilterState === CF_STATE_ON_ESC) {
        if (c === REPL_ENTER_DEBUG) {
          this.consoleFilterState = CF_STATE_OFF
        } else if (c === REPL_VAR_PREFIX) {
          this.consoleFilterState = CF_STATE_OFF
        } else {
          outBuf += REPL_ESC_DEBUG + c
          this.consoleFilterState = CF_STATE_ON
        }
      } else if (this.consoleFilterState === CF_STATE_OFF) {
        if (c === REPL_ESC_DEBUG) {
          this.consoleFilterState = CF_STATE_OFF_ESC
        }
      } else if (this.consoleFilterState === CF_STATE_OFF_ESC) {
        if (c === REPL_EXIT_DEBUG) {
          if (this.consoleFilterDebugRecurse > 0) {
            this.consoleFilterDebugRecurse--
            this.consoleFilterState = CF_STATE_OFF
          } else {
            this.consoleFilterState = CF_STATE_ON
          }
        } else if (c === REPL_ENTER_DEBUG) {
          this.consoleFilterState = CF_STATE_OFF
          // this.consoleFilterDebugRecurse++
        } else if (c === REPL_VAR_PREFIX) {
          this.consoleFilterState = CF_STATE_OFF
        } else {
          this.consoleFilterState = CF_STATE_OFF
        }
      } else if (this.consoleFilterState === CF_STATE_NO_ECHO) {
        if (this.echoFilter.length === 0) {
          this.consoleFilterState = CF_STATE_ON
          this.echoFilter = ''
        } else {
          this.echoFilter = this.echoFilter.substring(1)
        }
      }
    }

    return outBuf
  }

  // Characters received from serial port (echoed commands + responses)
  handleSerialChars = (buf) => {
    let strBuf = String.fromCharCode(...buf)

    // console.log('rx', buf, strBuf)

    // Tracebacks
    this.checkForTraceback(strBuf)

    if (this.lastTargetState !== TargetStates.LOADING) {
      // Variables Panel
      if (this.targetMode === TargetModes.DEBUG) {
        this.varsWatcher.check(strBuf)
      }

      // State
      this.trackDebugVsRepl(strBuf)

      // Console output
      const filteredBuf = this.filterConsole(strBuf)
      validatorController.handleConsoleMsg(filteredBuf)
      if (this.webTerminal?.terminalIO?.print) {
        this.webTerminal.onOutgoingCharacters(filteredBuf)
        // this.webTerminal.onOutgoingCharacters(strBuf)
      }
    }
  }
}
