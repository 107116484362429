import { userSessionGateway } from '../user-session-gateway'
import { pollerController } from '../../utils/poller-controller'

var poller = null

class UserSessionActivityPoller {
  start() {
    poller = setInterval(userSessionGateway.updateActivity, 60 * 1000)
  }

  stop() {
    clearInterval(poller)
    poller = null
  }
}

const userSessionActivityPoller = new UserSessionActivityPoller()
pollerController.addPoller('userSessionActivityPoller', userSessionActivityPoller.stop)
export { userSessionActivityPoller }