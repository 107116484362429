class CodeMarkerControl {
  constructor() {
    this.walkStep = 0
    this.zoneId = null // this gets set by the CodePanelContext
    this.zone = null
    this.overlayDom = null
    this.editor = null
    this.walk = []
    this.available = []
    this.allAvailableSteps = []
    this.overlayWidget = null
    this.lineHeight = 0
    this.markerList = []
    this.overlayDiv = null
    this.overlayHidden = false
  }

  setEditor = (editor, monaco) => {
    this.editor = editor
    this.lineHeight = this.editor.getOption(monaco.editor.EditorOptions.lineHeight.id)
    this.clearOverlay()
  }

  getLineNum = (marker) => {
    let lineNumberPair = this.markerList.find(el => el.marker.toString() === marker.toString())
    if (lineNumberPair) {
      return lineNumberPair.line
    }
    return -1
  }

  hideOverlay = () => {
    this.clearOverlay()
    this.overlayHidden = true
  }

  restoreOverlay = (step) => {
    this.setOverlay(step)
    this.overlayHidden = false
  }

  clearOverlay = () => {
    if (this.overlayWidget !== null) {
      this.editor.removeOverlayWidget(this.overlayWidget)
      this.overlayWidget = null
    }

    if (this.zoneId !== null){
      this.editor.changeViewZones((changeAccessor) => {
        changeAccessor.removeZone(this.zoneId)
        this.zoneId = null
      })
    }
  }

  stripMarkers = (code) => {
    const regex = /#@[\d]+/g
    this.markerList = []

    const chars = code.split('\n')
    chars.forEach((spl, idx) => {
      const matches = spl.match(regex)
      if (matches) {
        const newMarkers = matches.map((m) => {
          return {marker: m.slice(2), line: idx }
        })
        this.markerList = this.markerList.concat(newMarkers)
      }
    })

    return code.replace(regex, '')
  }

  isWalkDone = () => {
    return this.walkStep >= this.walk.length
  }

  restartWalk = () => {
    this.walkStep = 0
    this.clearOverlay()
  }

  setStepOverlayDiv = (overlayDiv) => {
    this.overlayDiv = overlayDiv
  }

  _getHeightInLines = (idx) => {
    return Math.ceil((this.walk[idx].pixelHeight + 44) / this.lineHeight)
  }

  loadSteps = (walkSteps) => {
    this.allAvailableSteps = walkSteps
  }

  setOverlay = (step=0) => {
    this.overlayWidget = {
      getId: () => 'overlay.zone.widget',
      getDomNode: () => this.overlayDiv,
      getPosition: () => null,
    }

    this.editor.addOverlayWidget(this.overlayWidget)

    const domNode = document.createElement('div')
    const marginDomNode = document.createElement('div')

    const getSpeechBubblePadding = () => {
      return (this.editor.getLayoutInfo().contentLeft - 15) + 'px'
    }

    this.zone = {
      afterLineNumber: this.getLineNum(this.walk[step].marker) + 1,
      heightInLines: this._getHeightInLines(step),
      domNode: domNode,
      marginDomNode: marginDomNode,
      onDomNodeTop: (top) => {
        this.overlayDiv.style.top = top + 'px'
        this.overlayDiv.style.paddingLeft = getSpeechBubblePadding()
        this.overlayDiv.style.paddingRight = getSpeechBubblePadding()
      },
      onComputedHeight: (height) => {
        this.overlayDiv.style.height = height + 'px'
        this.overlayDiv.style.paddingLeft = getSpeechBubblePadding()
        this.overlayDiv.style.paddingRight = getSpeechBubblePadding()
      },
    }

    this.editor.changeViewZones((changeAccessor) => {
      this.zoneId = changeAccessor.addZone(this.zone)
    })
  }

  startWalk = (availableMarkers) => { // array of markers that can be shown
    this.walk = []
    availableMarkers.forEach((marker) => {
      const step = this.allAvailableSteps.find(step => step.marker === marker)
      this.walk.push({...step})
    })

    this.restartWalk()

    this.setOverlay()
  }

  walkUpdateViewZone = () => {
    if (this.overlayHidden){
      this.restoreOverlay(this.walkStep)
    } else {
      this.editor.changeViewZones((changeAccessor) => {
        changeAccessor.removeZone(this.zoneId)
      })

      this.zone.afterLineNumber = this.getLineNum(this.walk[this.walkStep].marker) + 1
      this.zone.heightInLines = this._getHeightInLines(this.walkStep)

      this.editor.changeViewZones((changeAccessor) => {
        this.zoneId = changeAccessor.addZone(this.zone)
      })
    }
  }

  prevWalkStep = () => {
    if (this.walkStep > 0) {
      this.walkStep -= 1
      this.walkUpdateViewZone()
    }
  }

  nextWalkStep = () => {
    this.walkStep += 1
    if (this.isWalkDone()) {
      this.clearOverlay()
      return
    }

    this.walkUpdateViewZone()
  }

  getWalkStep = () => {
    return this.walkStep
  }
}

export const codeMarkerController = new CodeMarkerControl()