import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grow from '@material-ui/core/Grow'
import { TargetModes } from './TargetInterface'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import Split from 'react-split-pane'
import Typography from '@material-ui/core/Typography'
import { useDebugger } from './contexts/DebuggerContext'
import { lib } from './utils/hterm_all'
import { webTerminal } from './Players'

const useStyles = makeStyles(theme => ({
  drawer: {
    display: 'flex',
    height: '100%',
    borderTop: `1px solid ${theme.palette.divider}`,
    overflowY: 'auto',
  },
  drawerContent: {
    overflowY: 'auto',
    height: '100%',
  },
  terminalDrag: {
    pointerEvents: 'none',
  },
  // Most of this came from stackoverflow, and I am not happy with the appearance yet
  // https://stackoverflow.com/questions/53772429/material-ui-how-can-i-style-the-scrollbar-with-css-in-js
  // It at least gives us functional scrollbars...
  // TODO How to incorporate the light/dark theme into this?
  // TODO How to make it look like the other scrollbars?
  debugVariablesStyle: {
    height: '100%',
    width: '100%',
    backgroundColor:'#101010',
    overflow: 'auto',
    margin: 0,
    padding: 0,
    '&::-webkit-scrollbar': {
      width: '0.8em',
      height: '0.8em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      // outline: '1px solid slategrey',
    },
    '&::-webkit-scrollbar-corner': {
      display: 'none',
    },
  },
  nodeParagraph: {
    userSelect: 'none',
    fontWeight:'lighter',
    margin:0,
  },
  nodeTypography: {
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'center',
    width:'100%',
  },
}))

const defaultNodeOptions = () => {
  return {
    prefix: {
      value: null,
    },
    name: {
      value: null,
      color: '#CA86BD',
      fontStyle: 'normal',
    },
    data: {
      value: null,
      color: '#848484',
    },
    suffix: {
      value: null,
    },
  }
}
export default function InteractionDrawer(props) {
  const classes = useStyles()
  const [debuggerState] = useDebugger()
  const [debugVariables, setDebugVariables] = React.useState({
    locals: {
      id: '0',
      name: 'Locals',
    },
    globals: {
      id: '0',
      name: 'Globals',
    },
  })

  const renderTree = (nodes) => {
    const node = nodes.node ? nodes.node : defaultNodeOptions()
    return (
      <TreeItem key={nodes.id} nodeId={nodes.id} label={
        <Typography
          noWrap
          component={'div'}
          className={classes.nodeTypography}
          style={{gap:5}}
        >
          <p className={classes.nodeParagraph} style={{fontSize:14, color:node.name.color, fontStyle: node.name.fontStyle}}>
            {nodes.name}
            {nodes.name === null || node.data.value === null || node.data.value === undefined ? '' : ': '}
          </p>
          <p className={classes.nodeParagraph} style={{fontSize:14, color: node.data.color}}>
            {node.data.value === null || node.data.value === undefined ? '' : ' '+node.data.value}
          </p>
          {node.suffix.value ?
            <p className={classes.nodeParagraph} style={{fontSize:11, fontStyle: 'italic', color:'white'}}>
              {node.suffix.value}
            </p> :
            null
          }
        </Typography>
      }>
        {Array.isArray(nodes.children) ? nodes.children.map(node => renderTree(node)) : null}
      </TreeItem>
    )
  }

  const parseValue = (key, value) => {
    // TODO: Now that the Python side passes the stringified 'strValue' as well as the raw 'value' of each item,
    //       we could improve our performance, fidelity, and robustness of displaying Python datatypes by just using
    //       the strValue and perhaps even removing the raw 'value' from being sent.
    //       We could almost do this today, by using strValue below rather than manually converting JS data to Python format.
    //       However, the Python side doesn't build a complete hierarchical stringified view. So the child elements of a list
    //       for example, don't have strValue defined. So until the Python side gives us the whole tree we can't switch over
    //       to strValue completely.
    //       We are using strValue for "unknown" datatypes though, like custom objects (datetime, etc.)

    const MAX_STRVAL_LEN = 24

    const formatValueArray = (arr, frontChar, endChar) => {
      let formattedArr = arr.replaceAll(',', ', ').split(',')
      return formattedArr.length < 6 ?
        `${frontChar}${arr}${endChar}` :
        `${frontChar}${formattedArr.splice(0, 5)}...`
    }
    const formatChildArray = (arr) => {
      let arrLength = arr.length
      if (arrLength > 10){
        arr.splice(10, arrLength)
        arr.push({value:`(...${arrLength} items)`, pyValType:'seqEnd'})
      }
      return arr
    }
    const stringifyFixupBoolCaps = (key, value) => {
      // Where JSON.stringify() is used, change to Python capitalization. Drawback of
      // this approach is that the quoted string value "True" or "False" is shown.
      if (value === false) {
        return 'False'
      } else if (value === true) {
        return 'True'
      }
      return value
    }
    const truncateStrVal = (s) => {
      if (!s) {
        return '...'
      }
      return (s.length > MAX_STRVAL_LEN) ? s.slice(0, MAX_STRVAL_LEN) + '...' : s
    }

    var children = null
    var type = null

    const node = defaultNodeOptions()
    // var prefix = node.prefix
    var name = node.name
    name.value = key
    var data = node.data
    data.value = value
    var suffix = node.suffix
    let strValue = ''

    if (typeof(value) === 'object' && 'pyValType' in value){
      data.value = value.value
      type = value.pyValType
      strValue = value.strValue
    }
    if (!type) {
      type = typeof(value)
      if (type === 'object') {
        type = value.constructor.name
      }
    }
    switch (type) {
      // { type: ... }
      case 'str':
      case 'string': {
        data.color = '#D2917C'
        if (data.value.length > MAX_STRVAL_LEN){
          suffix.value = `(len=${data.value.length})`
          data.value = data.value.substring(0, MAX_STRVAL_LEN) + '...'
        }
        break
      }
      case 'int':
      case 'float':
      case 'number': {
        data.color = '#B2CEAB'
        break
      }
      case 'bool':
      case 'boolean': {
        data.value = String(data.value)
        data.value = data.value.charAt(0).toUpperCase() + data.value.slice(1)
        data.color = '#4894CA'
        break
      }
      case 'list': {
        children = formatChildArray(data.value)
        const listTuple = JSON.stringify(data.value, stringifyFixupBoolCaps)
        data.value = formatValueArray(listTuple.substring(1, listTuple.length-1), '[', ']')
        break
      }
      case 'Array':
      case 'tuple': {
        children = formatChildArray(data.value)
        const stringTuple = JSON.stringify(data.value, stringifyFixupBoolCaps)
        data.value = formatValueArray(stringTuple.substring(1, stringTuple.length-1), '(', ')')
        break
      }
      case 'set': {
        children = formatChildArray(data.value.$items)
        // Set items are burried under the $items key, so we pull and format them to
        // create a readable sneak peak
        if (data.value.$items){
          var setValue = ''
          data.value.$items.forEach((item)=> {
            setValue += item +', '
          }
          )
          data.value = formatValueArray(
            setValue.substring(0, setValue.length-2),
            '{', '}'
          )
        } else {
          data.value = null
        }
        break
      }
      case 'dict': {
        children = data.value
        let childrenKeys = Object.keys(children)
        if (childrenKeys.length > 10){
          childrenKeys.splice(10, childrenKeys.length)
          let newChildren = {}
          childrenKeys.forEach((key) => {
            newChildren[key] = children[key]
          })
          children = newChildren
        }
        var dictString = JSON.stringify(data.value, stringifyFixupBoolCaps)
        data.value = formatValueArray(
          dictString.substring(1, dictString.length-1).replaceAll('"', '\'').replaceAll(':', ': '),
          '{', '}'
        )
        break
      }
      case 'range': {
        data.value = strValue ? truncateStrVal(strValue) : `range(${children.start}, ${children.stop})`
        break
      }
      case 'NoneType': {
        data.value = 'None'
        break
      }
      case 'function':
      case 'method': {
        data.color = '#848484'
        break
      }
      case 'firiaModule': {
        children = data.value
        data.value = null
        name.fontStyle = 'italic'
        break
      }
      case 'seqEnd': {
        name.value = null
        data.color = 'white'
        break
      }
      case 'bytes': {
        children = data.value.source
        data.value = truncateStrVal(strValue)
        break
      }
      default: {
        data.value = truncateStrVal(strValue)
        data.color = '#4894CA'
        children = null
      }
    }

    return [node, children]
  }

  React.useEffect(() => {
    const formatDebugVariables = (variable, path) => {
      var children = []
      let objectKeys = []
      if (!variable) {
      } else {
        objectKeys = Object.keys(variable).sort((a, b) => {
          if (!isNaN(a)){
            return a-b
          } else {
            if (a === 'function variables'){
              return 1
            } else if (b === 'function variables'){
              return -1
            }
            var nameA = a.toUpperCase()
            var nameB = b.toUpperCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          }
        })
      }
      objectKeys.forEach((key) => {
        var [node, formattedChildren] = parseValue(key, variable[key])
        const id = path+key
        if (formattedChildren !== null && typeof(formattedChildren) === 'object' && formattedChildren.length !== 0) {
          // Function Variables gets special treatment to distinguish it from all other fields,
          // if we add special varibales in the future it should be added to this if statement.
          if (key === 'function variables'){
            node.name.color = '#a686ca'
            node.name.value = '[functions]'
          }
          children.push({
            id: id,
            name: node.name.value,
            node: node,
            children:formatDebugVariables(formattedChildren, id),
          })
        } else {
          children.push({
            id: id,
            name: node.name.value,
            node: node,
          })
        }
      })
      return children
    }
    var localsChildren = []
    var globalsChildren = []

    if (debuggerState.debugInfo.variables?.Locals){
      localsChildren = formatDebugVariables(debuggerState.debugInfo.variables.Locals, 'Locals')
      globalsChildren = formatDebugVariables(debuggerState.debugInfo.variables.Globals, 'Globals')
    }

    setDebugVariables({
      locals: {
        id: '0',
        name: 'Locals',
        children: localsChildren,
      },
      globals: {
        id: '0',
        name: 'Globals',
        children: globalsChildren,
      },
    })
  }, [debuggerState.debugInfo])

  React.useEffect(() => {
    // this should only ever happen one time on its initial open
    // TODO: make this be created on initial page open not on initial console open
    const drawerOpenedSetup = async () => {
      await lib.init()
      await webTerminal.init('terminal')
    }
    drawerOpenedSetup()
  }, [])

  return (
    <Grow
      // direction='up'
      in={props.isOpen}
      // mountOnEnter
      // unmountOnExit
      onEnter={() => {
        document.getElementById('terminal').focus()
        webTerminal.scrollEnd()
      }}
    >
      <div className={classes.drawer}>
        <Split
          split='vertical'
          primary='second'
          allowResize={true}
          style={{height:'100%'}}
          minSize={debuggerState.mode === TargetModes.DEBUG ? 50 : 0}
          maxSize={debuggerState.mode === TargetModes.DEBUG ? -50 : 0}
          defaultSize={debuggerState.mode === TargetModes.DEBUG ? 200 : 0}
          onDragStarted={()=>props.setDraggingTerminal(true)}
          onDragFinished={()=>props.setDraggingTerminal(false)}
        >
          <div
            id='terminal'
            className={
              props.draggingTerminal ?
                `${classes.drawerContent} ${classes.terminalDrag}` :
                classes.drawerContent
            }
          >
          </div>
          <div className={classes.debugVariablesStyle} >
            <TreeView
              defaultExpandIcon={<ChevronRightIcon />}
              defaultCollapseIcon={<ExpandMoreIcon />}
            >
              {renderTree(debugVariables.locals)}
            </TreeView>
            <TreeView
              defaultExpandIcon={<ChevronRightIcon />}
              defaultCollapseIcon={<ExpandMoreIcon />}
            >
              {renderTree(debugVariables.globals)}
            </TreeView>
          </div>
        </Split>
      </div>
    </Grow>
  )
}

InteractionDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}