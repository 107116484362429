// LineSenseArena
import { Entity, simScene } from '../SimScene'
import * as BABYLON from '@babylonjs/core'
import { assetPath, disposePromise, makeImpostor, hitCache } from '../utils/BabylonUtils'
import Classroom1 from '../assets/classroom1.glb'
import CloudyFieldEnv from '../assets/CloudyField.dds'
import StudioSoftboxEnv from '../assets/StudioSoftbox.env'
import { Waypoints } from './CommonProps'

const GROUND_FRICTION = 0.8
const GROUND_RESTITUTION = 0.7
class SimLights extends Entity {
  load = async (scene) => {
    this.scene = scene

    // Ambient lighting provided by HDR environment
    // this.envTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(StudioSoftboxEnv, scene)
    this.envTexture = await hitCache('Env_StudioSoftbox', () => {
      return BABYLON.CubeTexture.CreateFromPrefilteredData(StudioSoftboxEnv, scene)
    })
    scene.environmentTexture = this.envTexture

    // Directional shadow-casting light. Needs to be fairly close to bot in order to create crisp
    // (not blocky) shadows. Also position must be consistent with direction.
    this.light = new BABYLON.DirectionalLight('dir01', new BABYLON.Vector3(1, -2, 1), scene)
    this.light.position = new BABYLON.Vector3(-36, 18, -40)  // Light in (-x,-z) corner of room, so direction works
    this.light.intensity = 1.0

    // Visualize directional light source (beautiful yellow sun!)
    // const lightSphere = BABYLON.Mesh.CreateSphere('sphere', 10, 2, scene)
    // lightSphere.position = this.light.position
    // lightSphere.material = new BABYLON.StandardMaterial('light', scene)
    // lightSphere.material.emissiveColor = new BABYLON.Color3(1, 1, 0)
    // localAxes(3, scene).parent = lightSphere

    this.shadowGenerator = new BABYLON.ShadowGenerator(1024, this.light)
    this.shadowGenerator.useExponentialShadowMap = true

    // Note: A potential performance improvement (if shadows are found to be too burdensome) is
    //       to switch to using imposters as the shadow casters. The following line allows transparent
    //       meshes to cast shadows. This will require changing imposters to use "visibility=0" rather
    //       than "isVisible=false", since the former sets 100% transparent while the latter removes
    //       from render altogether. Would need to test to see if this helps performance!!
    // this.shadowGenerator.setTransparencyShadow(true)
  }

  getShadowGenerators = () => {
    return [this.shadowGenerator]
  }

  unload = async () => {
    const disposeList = [this.shadowGenerator, this.light]
    await Promise.all(disposeList.map(disposePromise))
    this.scene.environmentTexture = null
  }
}

class SimGround extends Entity {
  constructor(loadLineCourse) {
    super()
    this.loadLineCourse = loadLineCourse
  }

  load = async (scene) => {
    // Create the invisible ground parent mesh
    this.ground = BABYLON.Mesh.CreateGround('ground', 100, 100, 2, scene)
    this.ground.checkCollisions = true  // For camera collisions
    this.ground.isVisible = false
    this.shadowCasters = []
    this.shadowModelCasterLen = 0

    const [path, name] = assetPath(Classroom1)

    // Import the Classroom environment
    this.classroomMesh = await hitCache('ClassroomModels', async () => {
      return await BABYLON.SceneLoader.LoadAssetContainerAsync (path, name, scene)
    })
    // Currently NOT cloning environment model, instead trying to keep makeImpostor() and other
    // operations that modify the mesh idempotent. That means we can't dispose this, and instead
    // must add/remove it from scene.
    this.classroomMesh.addAllToScene()
    const meshes = this.classroomMesh.meshes
    this.rootMesh = meshes[0]

    // Set the ground mesh as the root node's parent
    this.rootMesh.parent = this.ground

    // Traverse meshes in this scene
    await Promise.all(meshes.map(async (mesh) => {
      // Add the ceiling light meshes with a white glow to the glowMeshes map
      if (mesh.name.substring(0, 23) === 'Classroom_Ceiling_Light') {
        simScene.glowMeshes.set(mesh.name, new BABYLON.Color4(1, 1, 1, 0.15))
        simScene.glLeds.addExcludedMesh(mesh) // Exclude this mesh from the LED glow layer
      }

      // Enable shadows on tile floor
      if (mesh.name === 'Plane') {
        // Note: Until we make all the signboards shadowcasters, we disable duplicate shadow on floor when line course is loaded.
        mesh.receiveShadows = !this.loadLineCourse
      }

      // Clone all sign board meshes to make their impostor box mesh. Only include
      // mesh names that begin with 'SignBoard' and end with '_primitive0' (if a child mesh)
      // if (mesh.name.startsWith('SignBoard') && !mesh.name.endsWith('_primitive1')) {
      if (mesh.name.startsWith('SignBoard')) {
        if (this.loadLineCourse) {
          mesh.isVisible = true
          if (mesh.name.endsWith('_primitive1')) {
            mesh.receiveShadows = true  // We definitely want shadows on the white signboards

            // Signboards should cast shadows also. But this doesn't work as-is, since a caster+receiver face
            // seems to shadow itself. Tried moving the caster to the _primitive0 box portion, but same issue.
            // That may be due to coincident surface with top face. For expedience chose to just disable shadows
            // on tile floor and live with some odd "ghost shadows" on signboard overpasses for now.
            // this.shadowCasters.push(mesh)
          } else {
            const signBoardImpostor = mesh.clone('ImpostorBox' + mesh.name)
            makeImpostor(signBoardImpostor, this.ground)
          }
        } else {
          mesh.isVisible = false
        }
      } else {
        makeImpostor(mesh, this.ground)
      }
      mesh.freezeWorldMatrix() // more testing required
    }))

    // Parent physics imposter must init last
    this.ground.physicsImpostor = new BABYLON.PhysicsImpostor(
      this.ground,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0, friction: GROUND_FRICTION, restitution: GROUND_RESTITUTION },
      scene
    )
    this.ground.freezeWorldMatrix()
  }

  // getShadowCasters = () => {
  //   return this.shadowCasters
  // }

  // hasShadowModelChanged = () => {
  //   const hasChanged = this.shadowModelCasterLen !== this.shadowCasters.length
  //   this.shadowModelCasterLen = this.shadowCasters.length
  //   return hasChanged
  // }

  unload = async () => {
    // Don't dispose environment model, just remove from scene
    this.rootMesh.parent = null
    this.classroomMesh.removeAllFromScene()

    await disposePromise(this.ground)
  }
}

class SimSkyBox extends Entity {
  load = async (scene) => {
    this.scene = scene
    this.skybox = BABYLON.Mesh.CreateBox('skyBox', 1000.0, scene)
    const skyboxMaterial = new BABYLON.StandardMaterial('skyBox', scene)
    skyboxMaterial.backFaceCulling = false  // viewing texture on inside of cube
    this.skybox.infiniteDistance = true   // follow camera's position
    skyboxMaterial.disableLighting = true  // no reflections on box

    this.envTexture = new BABYLON.CubeTexture(CloudyFieldEnv, scene)
    skyboxMaterial.reflectionTexture = this.envTexture
    skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE
    this.skybox.material = skyboxMaterial
  }

  unload = async () => {
    this.skybox.disposeArgs = [false, true] // materials too
    await disposePromise(this.skybox)
    // this.skybox.dispose(false, true)
  }
}

class ClassroomBase extends Entity {
  constructor(loadLineCourse) {
    super()
    this.name = 'Classroom'
    this.lights = new SimLights()
    this.ground = new SimGround(loadLineCourse)
    this.skybox = new SimSkyBox()
    this.waypoints = new Waypoints()
    this.defaultParams = {
      'bot': [
        {
          'playerPosition': [0, 0.2, -25],
          'playerRotation': 90, // Bot's rotation in degrees
        },
      ],
      'waypoints': [
        // {
        //   'name': 'Checkpoint 1',
        //   'position': [0, 0.2, -24],
        //   'rotation': [0, 0, 0],
        // },
      ],
    }
  }
  load = async (scene) => {
    scene.autoClear = false // Increase frame rate and performance
    scene.autoClearDepthAndStencil = false
    await this.ground.load(scene)
    await Promise.all([
      this.lights.load(scene),
      this.skybox.load(scene),
      this.waypoints.load(scene),
    ])
  }
  unload = () => Promise.all([
    this.lights.unload(),
    this.ground.unload(),
    this.skybox.unload(),
    this.waypoints.unload(),
  ])
  getShadowGenerators = () => {
    return this.lights.getShadowGenerators()
  }
  // getShadowCasters = () => {
  //   return this.ground.getShadowCasters()
  // }
  // hasShadowModelChanged = () => {
  //   return this.ground.hasShadowModelChanged()
  // }
  setOptions = (params) => {
    this.params = params ? params : this.defaultParams
    this.objectiveParams = this.params
    this.waypoints.setOptions(params)
  }
}

export class LineSenseArena extends ClassroomBase {
  constructor() {
    const loadLineCourse = true
    super(loadLineCourse)
    this.name = 'Line-follow 101'
  }
}

export class Classroom extends ClassroomBase {
  constructor() {
    const loadLineCourse = false
    super(loadLineCourse)
    this.name = 'Classroom'
  }
}
