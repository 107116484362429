import { appDefaults } from './BotSimDefaults'
import { cloneDeep } from 'lodash'
import { getTool } from './Toolbox/ToolboxUtil'
import { handleUserProgressChanged } from './content-manager/user-progress/user-progress-controllers'
import { submitScores } from './content-manager/contests/contest-use-cases/submitScores'
import { missionWasStartedBeforeContestStarted } from './content-manager/contests/contest-use-cases/contest-erases-progress'
import {
  objectiveIsScored,
  missionContainsScoredObjective,
} from './content-manager/lesson-content/lesson-content-use-cases'

// from https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
function shuffle(array) {
  let currentIndex = array.length
  let randomIndex = 0

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

class UserProgressControl {
  constructor() {
    this._setDefaults()
    this.hydrateUI = async () => {}
  }

  _deepCopyAppDefaults() {
    return cloneDeep(appDefaults.userProgress)
  }

  _setDefaults() {
    this.activeMissionId = appDefaults.userProgress.ui.activeMissionId
    this.saveProgress = async () => {}
    this.userProgress = this._deepCopyAppDefaults()
    this.userProgress.ui.focusedContent = 'mission'
  }

  async init (saveFunc, loadFunc, readyFunc, loggedOut) {
    // This only exists to unlock all the mission packs from the console.
    this.hydrateUI = async () => readyFunc(this.userProgress)

    if (loggedOut) {
      this._setDefaults()
    }

    this.saveProgress = async (userProgress) => {
      handleUserProgressChanged(userProgress)
      await saveFunc(userProgress)
    }
    this.userProgress = {
      ...(this._deepCopyAppDefaults()),
      ...(await loadFunc()), // will always return a default file
    }
    this.activeMissionId = this.userProgress.ui.activeMissionId

    await handleUserProgressChanged(this.userProgress, true)
    const {focusedContent, activateMissionId, activateMissionPackId, curFlowIndex, ...userUi} = this.userProgress.ui
    readyFunc(userUi)
  }

  _createNewFlowItem(flowItem) {
    const newItemData = {
      id: flowItem.id,
      type: flowItem.type,
      started: '',
      completed: '',
    }

    if (flowItem.type === 'objective') {
      newItemData.tries = 0
      if (objectiveIsScored(flowItem.id)) {
        newItemData.score = 0
        newItemData.bestScore = 0
      }
    } else {// if (flowItem.type === 'quiz') {
      newItemData.questions = flowItem.obj.questions.map((q) => {
        const qKeys = q.obj.answers.map(ans => ans.key)

        const newQuestionData = {
          id: q.id,
          answers: shuffle(qKeys),
          selected: [],
        }

        return newQuestionData
      })
    }
    return newItemData
  }

  _createFlowForProgress(missionData) {
    return missionData.map(this._createNewFlowItem)
  }

  _missionHasProgress(id) {
    if (this.userProgress.missions) {
      if (id in this.userProgress.missions) {
        return true
      }
    }
    return false
  }

  _getObjectiveRef(objectiveId) {
    return this.userProgress.missions[this.activeMissionId].flow
      .find(flowItem => flowItem.type === 'objective' && flowItem.id === objectiveId)
  }

  _getQuizRef(quizId) {
    return this.userProgress.missions[this.activeMissionId].flow
      .find(flowItem => flowItem.type === 'quiz' && flowItem.id === quizId)
  }

  async setObjectiveStarted(objectiveId) {
    window.gtag('event', 'level_start', {'level_name':String(objectiveId)})
    const obj = this._getObjectiveRef(objectiveId)
    obj.started = new Date()
    await this.saveProgress(this.userProgress)
  }

  async setObjectiveCompleted(objectiveId, xp, isMsnComplete) {
    window.gtag('event', 'level_end', {'level_name':String(objectiveId),'success':true})
    const obj = this._getObjectiveRef(objectiveId)
    if (obj.completed === undefined || obj.completed === '') {
      obj.completed = new Date()
      obj.xp = xp
    }
    if (isMsnComplete && this.userProgress.missions[this.activeMissionId].completed === '') {
      this.userProgress.missions[this.activeMissionId].completed = new Date()
    }
    await this.saveProgress(this.userProgress)
  }

  async incrementObjectiveTries(objectiveId) {
    const obj = this._getObjectiveRef(objectiveId)
    obj.tries++
    // I thought this would be a reasonable place to log a "level_up" gtag, but it did not fire...
    await this.saveProgress(this.userProgress)
  }

  async setMissionComplete() {
    this.userProgress.missions[this.activeMissionId].completed = new Date()
    await this.saveProgress(this.userProgress)
  }

  async setQuizStarted(quizId) {
    const quiz = this._getQuizRef(quizId)
    quiz.started = new Date()
    await this.saveProgress(this.userProgress)
  }

  async setQuizCompleted(quizId) {
    const quiz = this._getQuizRef(quizId)
    quiz.completed = new Date()
    await this.saveProgress(this.userProgress)
  }

  async setQuestionComplete(quizId, questionId, xp) {
    const quiz = this._getQuizRef(quizId)
    quiz.questions.find(q => q.id === questionId).xp = xp
    await this.saveProgress(this.userProgress)
  }

  async setAnswerSelected(quizId, questionId, answerKey, xp, isQuestionComplete, isQuizComplete, isMsnComplete) {
    const quiz = this._getQuizRef(quizId)
    quiz.questions.find(q => q.id === questionId).selected.push(answerKey)
    if (isQuestionComplete) {
      quiz.questions.find(q => q.id === questionId).xp = xp
    }
    if (isQuizComplete && quiz.completed === '') {
      quiz.completed = new Date()
    }
    if (isMsnComplete && this.userProgress.missions[this.activeMissionId].completed === '') {
      this.userProgress.missions[this.activeMissionId].completed = new Date()
    }
    await this.saveProgress(this.userProgress)
  }

  async activateMission(missionData) {
    if (!this._missionHasProgress(missionData.id) || missionWasStartedBeforeContestStarted(missionData.id)) {
      const newMissionData = {
        started: new Date(),
        completed: '',
        flow: this._createFlowForProgress(missionData.flow),
      }
      if (missionContainsScoredObjective(missionData.id)) {
        newMissionData.score = 0
        newMissionData.bestScore = 0
      }
      this.userProgress.missions[missionData.id] = newMissionData
      await this.saveProgress(this.userProgress)
    } else { // if mission already in progress
      let hasChanges = false
      missionData.flow.forEach((item, index) => {
        // check for newly added flow items
        const flowIdx = this.userProgress.missions[missionData.id].flow.findIndex(i => i.id === item.id)
        if (flowIdx === -1) {
          hasChanges = true
          const newFlowProgess = this._createNewFlowItem(item)
          this.userProgress.missions[missionData.id].flow.splice(index, 0, newFlowProgess)
        }
        if (this.userProgress.missions[missionData.id].flow[flowIdx]?.score === undefined && objectiveIsScored(item.id)) {
          hasChanges = true
          this.userProgress.missions[missionData.id].flow[flowIdx].score = 0
          this.userProgress.missions[missionData.id].flow[flowIdx].bestScore = 0
        }
      })
      if (hasChanges) {
        await this.saveProgress(this.userProgress)
      }
    }

    this.activeMissionId = missionData.id
    return this.userProgress.missions[missionData.id]
  }

  // This will not save userProgress.
  // You will need to activate a mission first to prevent a bad state.
  activateMissionPack(missionPackData) {
    const completeList = {}
    missionPackData.missions.forEach((msn) => { // return array of booleans
      if (this._missionHasProgress(msn.id) && !missionWasStartedBeforeContestStarted(msn.id)) {
        completeList[msn.id] = this.userProgress.missions[msn.id].completed !== ''
      } else {
        completeList[msn.id] = false
      }
    })
    return completeList
  }

  async saveUiState(ui) {
    this.userProgress.ui = {...ui}
    await this.saveProgress(this.userProgress)
  }

  async addToolFound(toolMatch) {
    if (toolMatch) {
      const match = getTool(toolMatch)?.name
      if (match) {
        if (!this.userProgress.toolsFound.includes(match)) {
          this.userProgress.toolsFound.push(match)
        }

        this.userProgress.lastTool = match
        await this.saveProgress(this.userProgress)
      }
    }
  }

  async updateScore(flowId, score) {
    const missionProg = this.userProgress.missions[this.activeMissionId]
    if (!missionProg.score) {
      missionProg.score = 0
    }
    const flowItemProg = this.userProgress.missions[this.activeMissionId].flow.find(({id}) => id === flowId)
    if (!flowItemProg.score) {
      flowItemProg.score = 0
    }

    flowItemProg.score = score
    if (!flowItemProg.bestScore || score > flowItemProg.bestScore) {
      flowItemProg.bestScore = score
    }

    let missionScore = 0
    missionProg.flow.forEach((flowItem) => {
      if (!!flowItem && 'score' in flowItem) {
        missionScore += flowItem.score
      }
    })

    missionProg.score = missionScore
    if (!missionProg.bestScore || missionProg.score > missionProg.bestScore) {
      missionProg.bestScore = missionProg.score
    }

    await this.saveProgress(this.userProgress)
    if (flowItemProg.bestScore === score) {
      await submitScores()
    }
  }
}

export const userProgressController = new UserProgressControl()
